import React, { useEffect } from "react";
import tvgConf from "@tvg/conf";
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Header as HeaderTitle,
  useMediaQuery,
  breakpoints,
  useColorTokens
} from "@tvg/design-system";
import { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import { Header, Container, TabContainer } from "./styled-components";
import Account from "../Account";
import Betting from "../Betting";
import Notifications from "../Notifications";
import onPageView from "../../utils/gtm/pageViewFDRAndTVG5";

export interface PreferencesCenterProps {
  tabs: Array<{
    title: string;
    qaLabel?: string;
    value: string;
  }>;
  hasAlert: boolean;
  onSelect: (value: PreferencesTabEnum) => void;
  selectedTab: PreferencesTabEnum;
  isMobileLayout?: boolean;
  hasNotificationTab?: boolean;
}

const PreferencesCenter = ({
  tabs,
  hasAlert,
  onSelect,
  selectedTab,
  isMobileLayout = false,
  hasNotificationTab = false
}: PreferencesCenterProps) => {
  const colorTokens = useColorTokens();
  // Since the media query breakpoint is under cookie, there is a need to check device
  // For places that already are using Preferences
  const isMobile =
    useMediaQuery(breakpoints.tablet.max.sm) ||
    isMobileLayout ||
    tvgConf().device === "mobile";

  useEffect(() => {
    onPageView({
      selectedTab
    });
  }, [selectedTab]);

  return (
    <Container>
      {!isMobile && (
        <Header data-qa-label="preferences-header">
          <HeaderTitle
            tag="h1"
            fontFamily="bold"
            qaLabel="preferences-header-title"
            color={colorTokens.content.strong}
          >
            Preferences
          </HeaderTitle>
        </Header>
      )}
      <Tabs
        selectedValue={selectedTab}
        onChange={(value) => onSelect(value as PreferencesTabEnum)}
        isStretched
        qaLabel="preferences-tabs"
        useViewComponent
        isFullHeight
        headerPadding={isMobile ? "0" : "0 160px"}
      >
        <TabList qaLabel="tabList" isStretched={isMobile}>
          {tabs.map((tab, index) => (
            <Tab
              key={index.toString()}
              value={tab.value}
              title={tab.title}
              qaLabel={tab.qaLabel}
            />
          ))}
        </TabList>
        {hasNotificationTab ? (
          <TabPanel value="NOTIFICATIONS">
            <TabContainer
              data-qa-label="tab-children-container"
              hasAlert={hasAlert}
            >
              <Notifications />
            </TabContainer>
          </TabPanel>
        ) : (
          <></>
        )}
        <TabPanel value="BETTING">
          <TabContainer
            data-qa-label="tab-children-container"
            hasAlert={hasAlert}
          >
            <Betting />
          </TabContainer>
        </TabPanel>
        <TabPanel value="ACCOUNT">
          <TabContainer
            data-qa-label="tab-children-container"
            hasAlert={hasAlert}
          >
            <Account />
          </TabContainer>
        </TabPanel>
      </Tabs>
    </Container>
  );
};

export default PreferencesCenter;
