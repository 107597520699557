import { get } from "lodash";
import { Action, Dispatch } from "redux";
import { batch } from "react-redux";
import uc from "@tvg/api/uc";
import uam from "@tvg/api/uam";
import {
  NotificationSwitch,
  PushStringTypes,
  PushTypes,
  RaceMtpAlertBlock,
  RaceMtpOptions,
  SubscriptionGroupEnum,
  SubscriptionGroupType
} from "./types";
import {
  hideAlertPrefAction,
  setMuteAllCommunications,
  setNotificationsSwitchAction,
  showAlertPrefAction
} from "../redux/actions";

export const setStateMtpRaceAlert = async (
  newMtpValue: RaceMtpOptions,
  mtpRaceAlerts: Array<RaceMtpAlertBlock>,
  setMtpRaceAlerts: (raceMtpAlertBlock: Array<RaceMtpAlertBlock>) => void,
  accountNumber?: string,
  dispatch?: Dispatch<Action>,
  action?: Action
) => {
  if (newMtpValue) {
    const tempMtpRaceAlerts = [...mtpRaceAlerts];

    tempMtpRaceAlerts[0].enable = newMtpValue === RaceMtpOptions.zero;
    tempMtpRaceAlerts[1].enable = newMtpValue === RaceMtpOptions.two;
    tempMtpRaceAlerts[2].enable = newMtpValue === RaceMtpOptions.five;

    if (accountNumber && typeof dispatch === "function" && action) {
      try {
        await uam.setUserPref(accountNumber, "raceAlertMtp", newMtpValue);

        dispatch(action);
      } catch (e) {
        console.error("Problem updating mtp race alert preference");
      }
    }

    setMtpRaceAlerts(tempMtpRaceAlerts);
  }
};

export const requireSubscriptionGroups = async (
  accountNumber: string,
  setIsLoadingSubscription: (value: boolean) => void,
  dispatch: Dispatch
) => {
  // Check email subscriptions
  try {
    const { data } = await uc.getSubscriptionGroups(accountNumber);

    const isOffersEmailEnabled = data.find(
      (group: SubscriptionGroupType) =>
        group.name === SubscriptionGroupEnum.email_offers_news
    )?.isSubscribed;

    const isPromosEmailEnabled = data.find(
      (group: SubscriptionGroupType) =>
        group.name === SubscriptionGroupEnum.email_promotion_activity
    )?.isSubscribed;

    const isOtherFanduelEmailEnabled = data.find(
      (group: SubscriptionGroupType) =>
        group.name === SubscriptionGroupEnum.email_fanduel_comms
    )?.isSubscribed;

    batch(() => {
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.email_offers_news,
          isOffersEmailEnabled || false
        )
      );
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.email_promotion_activity,
          isPromosEmailEnabled || false
        )
      );
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.email_fanduel_comms,
          isOtherFanduelEmailEnabled || false
        )
      );
    });
    setIsLoadingSubscription(false);
  } catch (e) {
    console.error("Error while fetching subscription groups");
  }
};

// Method to request the push notifications
export const requireAttributes = async (
  accountNumber: string,
  setIsLoadingAttributes: (value: boolean) => void,
  dispatch: Dispatch
) => {
  try {
    const response = await uc.getAttributes(accountNumber);
    const pushSettings: PushTypes = get(response, "data", {
      push_offers_and_news: false,
      push_promotion_activity: false,
      talent_picks: false,
      push_fanduel_comms: false
    });

    batch(() => {
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.push_offers_and_news,
          !!pushSettings.push_offers_and_news
        )
      );
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.push_promotion_activity,
          !!pushSettings.push_promotion_activity
        )
      );
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.talent_picks,
          !!pushSettings.talent_picks
        )
      );
      dispatch(
        setNotificationsSwitchAction(
          NotificationSwitch.push_fanduel_comms,
          !!pushSettings.push_fanduel_comms
        )
      );
    });
    setIsLoadingAttributes(false);
  } catch (e) {
    console.error("Something went wrong while fetching push settings");
  }
};

export const setSubscriptionGroup = async (
  dispatch: Dispatch,
  accountNumber: string,
  subscriptionGroupName: SubscriptionGroupEnum,
  isSubscribed: boolean,
  stateType: NotificationSwitch,
  setIsLoadingRequest: (value: boolean) => void
) => {
  setIsLoadingRequest(true);
  dispatch(hideAlertPrefAction());

  const oppositeValue = !isSubscribed;
  try {
    await uc.setSubscriptionGroups(
      accountNumber,
      subscriptionGroupName,
      oppositeValue
    );
    dispatch(setNotificationsSwitchAction(stateType, oppositeValue));
  } catch (e) {
    dispatch(showAlertPrefAction("error-single", oppositeValue));
    dispatch(setNotificationsSwitchAction(stateType, oppositeValue));
  }
  setIsLoadingRequest(false);
};

export const setAttribute = async (
  dispatch: Dispatch,
  accountNumber: string,
  pushType: PushStringTypes,
  newValue: boolean,
  stateType: NotificationSwitch,
  setIsLoadingRequest: (value: boolean) => void
) => {
  try {
    setIsLoadingRequest(true);
    dispatch(hideAlertPrefAction());

    await uc.setAttributes(accountNumber, {
      [pushType]: newValue
    });
    dispatch(setNotificationsSwitchAction(stateType, newValue));
  } catch (e) {
    dispatch(showAlertPrefAction("error-single", newValue));
  }
  setIsLoadingRequest(false);
};

export const muteUnmuteAllNotifications = async (
  dispatch: Dispatch,
  accountNumber: string,
  isMuteAll: boolean,
  setIsLoading: (value: boolean) => void
) => {
  setIsLoading(true);
  dispatch(hideAlertPrefAction());

  try {
    await uc.changeEmailSubscriptionState(
      accountNumber,
      isMuteAll ? "unsubscribed" : "subscribed"
    );

    dispatch(setMuteAllCommunications(!isMuteAll));
    dispatch(
      showAlertPrefAction(
        isMuteAll ? "success.mute" : "success.unmute",
        false,
        isMuteAll
      )
    );

    if (isMuteAll) {
      try {
        const {
          data: { removedNotificationList }
        } = await uc.deleteUserNotifications(accountNumber);

        batch(() => {
          Object.keys(removedNotificationList).forEach((key) => {
            // @ts-ignore
            const stateType = NotificationSwitch[key];
            if (stateType) {
              dispatch(setNotificationsSwitchAction(stateType, false));
            }
          });
        });
      } catch (e) {
        console.error("Error while removing notifications");
      }
    } else {
      try {
        const {
          data: { activatedNotificationList }
        } = await uc.subscribeAllNotifications(accountNumber);

        batch(() => {
          Object.keys(activatedNotificationList).forEach((key) => {
            // @ts-ignore
            const stateType = NotificationSwitch[key];
            const newStateValue = activatedNotificationList[key];
            if (stateType && newStateValue) {
              dispatch(setNotificationsSwitchAction(stateType, newStateValue));
            }
          });
        });
      } catch (e) {
        dispatch(showAlertPrefAction("error.unmute", false, false));
      }
    }
  } catch (e) {
    dispatch(
      showAlertPrefAction(
        isMuteAll ? "error.mute" : "error.unmute",
        false,
        true
      )
    );
  }

  setIsLoading(false);
};

export const getSubscriptionAttributes = async (
  dispatch: Dispatch,
  accountNumber: string,
  setIsLoadingAttributes: (value: boolean) => void,
  setIsLoadingSubscription: (value: boolean) => void
) => {
  try {
    const {
      data: { emailSubscriptionState }
    } = await uc.getEmailSubscriptionState(accountNumber);

    if (emailSubscriptionState === "unsubscribed") {
      batch(() => {
        Object.keys(NotificationSwitch).forEach((key) => {
          // @ts-ignore
          const stateType = NotificationSwitch[key];
          if (stateType) {
            dispatch(setNotificationsSwitchAction(stateType, false));
          }
        });
      });

      dispatch(showAlertPrefAction("warning", false, true));
      dispatch(setMuteAllCommunications(false));
      setIsLoadingSubscription(false);
      setIsLoadingAttributes(false);
    } else {
      dispatch(setMuteAllCommunications(true));
      requireAttributes(accountNumber, setIsLoadingAttributes, dispatch);
      requireSubscriptionGroups(
        accountNumber,
        setIsLoadingSubscription,
        dispatch
      );
    }
  } catch (e) {
    console.error("Error while fetching main status");
  }
};
