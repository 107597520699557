import tvgConf from "@tvg/conf";
import { SubscriptionGroupType } from "@tvg/sh-lib-preferences/utils/types";
import getProtocol from "../protocolSetter";
import requester from "../requester";
import { serviceResponse } from "../types";

export type SubscriptionGroupName =
  | "email_offers_news"
  | "email_promotion_activity"
  | "email_fanduel_comms";

export type EmailSubscriptionState = "subscribed" | "unsubscribed" | "opted_in";

const ucService: string = "service.uc";
const getUcServiceUrl = (): string =>
  `${getProtocol()}${tvgConf().config(ucService)}`;

const getSubscriptionGroups = (
  accountId: string
): Promise<serviceResponse<[SubscriptionGroupType]>> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/subscription-groups`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const setSubscriptionGroups = (
  accountId: string,
  subscriptionGroupName: SubscriptionGroupName,
  subscribe: boolean
): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/subscription-groups/${subscriptionGroupName}`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      subscribe
    }
  });

const getAttributes = (accountId: string): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/attributes`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const setAttributes = (
  accountId: string,
  attributes: Record<string, boolean>
): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/attributes`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      attributes
    }
  });

const setUserSubscription = (
  accountId: string,
  subscriptionGroupName: SubscriptionGroupName,
  email: string,
  active: boolean
): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/subscriptions/${subscriptionGroupName}?email=${email}&active=${active.toString()}`,
    method: "put",
    headers: { "x-tvg-context": tvgConf().context() }
  });

const deleteUserNotifications = (accountId: string): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/subscriptions`,
    method: "delete",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const subscribeAllNotifications = (
  accountId: string
): Promise<
  serviceResponse<{
    activatedNotificationList: Record<string, boolean>;
  }>
> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/subscriptions`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const changeEmailSubscriptionState = (
  accountId: string,
  emailSubscriptionState: EmailSubscriptionState
): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/email/status`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      emailSubscriptionState
    }
  });

const getEmailSubscriptionState = (
  accountId: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/email/status`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const unsubscribeAllMainStatus = (
  accountId: string,
  email: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getUcServiceUrl()}/users/${accountId}/subscriptions/main/unsubscribe?email=${email}`,
    method: "delete",
    withCredentials: false,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const UC_SERVICE = {
  getSubscriptionGroups,
  setSubscriptionGroups,
  getAttributes,
  setAttributes,
  setUserSubscription,
  deleteUserNotifications,
  subscribeAllNotifications,
  changeEmailSubscriptionState,
  getEmailSubscriptionState,
  unsubscribeAllMainStatus
};

export default UC_SERVICE;
