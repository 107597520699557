import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { graphql } from "@apollo/client/react/hoc";
import uam from "@tvg/api/uam";
import { formatNumber } from "@tvg/formatter/";
import { setBetConfirmationPreference } from "@tvg/mobile-account/src/actions";
import tvgConf from "@tvg/conf";
import {
  filterNotAllowedBets,
  formatBetAmountsForService,
  getWagerGroupsValues,
  keyDown,
  setWagerCategoriesToState
} from "@tvg/sh-lib-preferences/utils/bettingUtils";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { usePrevious } from "@tvg/custom-hooks";
import ApolloOptions from "@tvg/sh-lib-preferences/graphql/options.graph";
import AllWagerTypesQuery from "@tvg/sh-lib-preferences/graphql/queries/AllWagerTypes";
import {
  getBetPrefs,
  getBettingPrefsCenterMessages,
  getLegacyBetType,
  getShowBetConfirm
} from "@tvg/sh-lib-preferences/redux/selectors";
import { openConfirmationModalPrefAction } from "@tvg/sh-lib-preferences/redux/actions";
import { setDefaultBetPrefs } from "@tvg/shared-actions/UserActions";
import {
  GraphWagerType,
  WagerGroupsItem,
  WagerGroups
} from "@tvg/sh-lib-preferences/utils/types";
import { getAccountNumber } from "@urp/store-selectors";
import { onGenericGtmEvent } from "../../utils/gtm/generic";
import { confirmationModalGtmEvent } from "../../utils/gtm/confirmModal";
import BettingPreferences from "./BettingPreferences";
import MobileLoading from "../Loading/Mobile";
import DesktopLoading from "../Loading/Desktop";
import onToggleRequestBetConfirmation from "../../utils/gtm/togglePreference";

interface Props {
  availableWagerTypes: GraphWagerType[];
}

export const BettingComponent = ({ availableWagerTypes = [] }: Props) => {
  // Since the media query breakpoint is under cookie, there is a need to check device
  // For places that already are using Preferences
  const isMobile =
    useMediaQuery(breakpoints.tablet.max.sm) || tvgConf().device === "mobile";
  const dispatch = useDispatch();

  const legacyBetType = useSelector(getLegacyBetType);
  const defaultBetPrefs = useSelector(getBetPrefs);
  const showBetConfirmPref = useSelector(getShowBetConfirm);
  const accountNumber = useSelector(getAccountNumber);
  const bettingCapi = useSelector(getBettingPrefsCenterMessages);

  const [isLoadingBetConfirmation, setIsLoadingBetConfirmation] =
    useState(false);
  const [wagerGroups, setWagerGroups] = useState(
    setWagerCategoriesToState(defaultBetPrefs.default_bet_amount) as WagerGroups
  );
  const previousDefaultBetAmount = usePrevious(
    // @ts-ignore
    defaultBetPrefs.default_bet_amount
  );

  useEffect(() => {
    if (
      JSON.stringify(previousDefaultBetAmount || {}) !==
      JSON.stringify(defaultBetPrefs.default_bet_amount || {})
    ) {
      setWagerGroups(
        setWagerCategoriesToState(defaultBetPrefs.default_bet_amount)
      );
    }
  }, [JSON.stringify(defaultBetPrefs.default_bet_amount)]);

  const updateUserPref = async ({
    wagerId,
    useMinimum,
    useCustom
  }: {
    wagerId?: string;
    useMinimum?: boolean;
    useCustom?: boolean;
  }) => {
    const defaultBetPreference = {
      defaultBetType:
        wagerId !== undefined
          ? wagerId
          : defaultBetPrefs.defaultBetType || legacyBetType,
      useMinimumBetAmount:
        useMinimum !== undefined
          ? useMinimum
          : defaultBetPrefs.useMinimumBetAmount,
      useCustomBetAmount:
        useCustom !== undefined
          ? useCustom
          : defaultBetPrefs.useCustomBetAmount,
      default_bet_amount: formatBetAmountsForService(wagerGroups)
    };

    await uam.setUserPref(
      accountNumber,
      "bet_prefs",
      JSON.stringify(defaultBetPreference)
    );

    dispatch(setDefaultBetPrefs(defaultBetPreference));
  };

  const onOptionChange = async (value: string) => {
    let useMinimum;
    let useCustom;

    switch (value) {
      case "useMinimumBetAmount":
        useMinimum = true;
        useCustom = false;
        break;
      case "useCustomBetAmount":
        useMinimum = false;
        useCustom = true;
        break;
      default:
        useMinimum = true;
        useCustom = false;
    }

    await updateUserPref({ useCustom, useMinimum });

    onGenericGtmEvent({
      gaEventAction: "Default Bet Amount Selected",
      gaEventLabel: useMinimum
        ? get(bettingCapi, "betAmount.label[0]", "")
        : get(bettingCapi, "betAmount.label[1]", ""),
      module: "Betting"
    });
  };

  const updateBetAmount = (value: string, item: WagerGroupsItem) => {
    let amount = value || "";

    if (amount.includes("-")) {
      amount = amount.slice(1);
    }

    const newItem = {};
    // @ts-ignore
    newItem[item.group] = {
      group: item.group,
      title: item.title,
      value: amount
    };
    setWagerGroups({ ...wagerGroups, ...newItem });
  };

  const onInputBlur = async (item: WagerGroupsItem) => {
    // hack to fix input focus keyboard issue on iOS
    if (typeof window !== "undefined") {
      setTimeout(() => window.scroll(), 100);
    }

    if (+item.value) {
      try {
        updateBetAmount(formatNumber(item.value), item);
        await updateUserPref({});
      } catch (e) {
        console.error("Something went wrong!", e);
      }
    }
  };

  const selectWagerType = async (wagerId: string, wagerText: string) => {
    try {
      await updateUserPref({ wagerId });
      onGenericGtmEvent({
        gaEventAction: "Default Bet Type Selected",
        gaEventLabel: wagerText,
        module: "Betting"
      });
    } catch (e) {
      console.error("Something went wrong!", e);
    }
  };

  const updateShowBetConfirm = async () => {
    setIsLoadingBetConfirmation(true);
    const showBetConfirm = showBetConfirmPref === "1" ? "0" : "1";
    await uam.setUserPref(accountNumber, "show_bet_confirm", showBetConfirm);
    dispatch(setBetConfirmationPreference(showBetConfirm));

    onToggleRequestBetConfirmation({
      module: "betting",
      setting: "bet_confirmation",
      value: showBetConfirm
    });

    // Mostrar a malta o código abaixo antes do commit
    let gaEventAction;
    if (tvgConf().device === "mobile") {
      gaEventAction = showBetConfirm === "1" ? "Toggled On" : "Toggled Off";
    } else {
      gaEventAction =
        showBetConfirm === "1" ? "Checkbox Checked" : "Checkbox Unchecked";
    }
    onGenericGtmEvent({
      gaEventAction,
      gaEventLabel: "Bet confirmation - Request bet confirmation",
      module: "Betting"
    });
    setIsLoadingBetConfirmation(false);
  };

  const wagerGroupsList =
    Object.keys(wagerGroups).length > 0
      ? getWagerGroupsValues(wagerGroups)
      : [];

  const hasDefaultBettingPreferences =
    showBetConfirmPref === "1" &&
    defaultBetPrefs.useMinimumBetAmount &&
    !defaultBetPrefs.useCustomBetAmount &&
    defaultBetPrefs.defaultBetType === "0";

  if (!availableWagerTypes.length) {
    return isMobile ? <MobileLoading /> : <DesktopLoading />;
  }

  return (
    <>
      {wagerGroupsList.length > 0 && (
        <BettingPreferences
          wagerTypes={filterNotAllowedBets(availableWagerTypes)}
          defaultBetType={defaultBetPrefs.defaultBetType || legacyBetType}
          onSelectWagerType={selectWagerType}
          wagerGroups={wagerGroupsList}
          onOptionChange={onOptionChange}
          isMainWagerGroupsShown={defaultBetPrefs.useCustomBetAmount}
          updateBetAmount={updateBetAmount}
          onInputBlur={onInputBlur}
          onKeyDown={keyDown}
          updateShowBetConfirm={updateShowBetConfirm}
          showBetConfirm={showBetConfirmPref}
          bettingCapi={bettingCapi}
          hasDefaultBettingPreferences={hasDefaultBettingPreferences}
          handleResetPreferences={() => {
            dispatch(openConfirmationModalPrefAction("BETTING"));
            confirmationModalGtmEvent(true);
          }}
          isLoadingBetConfirmation={isLoadingBetConfirmation}
        />
      )}
    </>
  );
};

// @ts-ignore
export default graphql(AllWagerTypesQuery, ApolloOptions)(BettingComponent);
