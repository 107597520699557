import React, { useContext, useState, useEffect } from "react";
import {
  StyledContainer,
  StyledLabel,
  StyledLabelContainer,
  StyledCheckbox
} from "./styled-components";
import { CheckboxGroup, GroupContext } from "./components/CheckboxGroup";
import type { CheckboxCompoundComponent, CheckboxProps } from "./types";

export const Checkbox = (({
  value,
  label,
  isInvalid = false,
  qaLabel = value,
  ...rest
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const { onChange, isDisabled = false, values } = useContext(GroupContext);

  useEffect(() => {
    setIsChecked(values.has(value));
  }, [values]);

  const selectHandler = () => {
    if (isDisabled) return;

    const currentState = !isChecked;

    setIsChecked(currentState);

    if (currentState) {
      values?.add(value);
    } else {
      values?.delete(value);
    }

    onChange(Array.from(values));
  };

  return (
    <StyledContainer data-qa-label={qaLabel} {...rest}>
      <StyledCheckbox
        name={value}
        data-qa-label={`${qaLabel}-input`}
        data-invalid={isInvalid}
        aria-label="checkbox"
        aria-checked={isChecked}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onClick={selectHandler}
      />
      {label !== "" && (
        <StyledLabelContainer onClick={selectHandler}>
          <StyledLabel
            isDisabled={isDisabled}
            data-qa-label={`${qaLabel}-label`}
          >
            {label}
          </StyledLabel>
        </StyledLabelContainer>
      )}
    </StyledContainer>
  );
}) as CheckboxCompoundComponent<typeof CheckboxGroup>;

export type { CheckboxProps };
Checkbox.Group = CheckboxGroup;
