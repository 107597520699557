import { createStore, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers, { initialState, AppState } from "./reducers/index";

const composeEnhancers = composeWithDevTools({
  name: "tvg-preferences-standalone"
});

export default (state: AppState = initialState): Store<AppState> => {
  const middlewares = [thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(reducers, state as never, enhancer);

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept("./reducers", () =>
      import("./reducers/index").then((reducerModule) =>
        store.replaceReducer(reducerModule.default)
      )
    );
  }

  // @ts-ignore
  return store;
};
