import { isTvg5 } from "@tvg/utils/generalUtils";
import mediator from "@tvg/mediator";
import uwtService from "@tvg/api/uwt";

interface PageViewGTMEvent {
  accountId: string;
  selectedTab: string;
  residenceState: string;
  pathname?: string;
  userIsLoggedIn?: boolean;
}

const onPageViewGTMEvent = ({
  accountId,
  selectedTab,
  residenceState,
  pathname,
  userIsLoggedIn = true
}: PageViewGTMEvent) =>
  !isTvg5() &&
  uwtService
    .getBalance(accountId, false)
    .then((response: { data: { balance: { toFixed: Function } } }) => {
      mediator.base.dispatch({
        type: "PREFERENCES:PAGE_VIEW",
        payload: {
          page:
            pathname === "/unsubscribe"
              ? pathname
              : `/preferences#${selectedTab.toLowerCase()}`,
          accountId: String(accountId),
          loginStatus: userIsLoggedIn ? "Logged In" : "Logged Out",
          residenceState,
          balance: String(response.data.balance.toFixed(2))
        }
      });
    })
    .catch(() => {
      mediator.base.dispatch({
        type: "PREFERENCES:PAGE_VIEW",
        payload: {
          page:
            pathname === "/unsubscribe"
              ? pathname
              : `/preferences#${selectedTab.toLowerCase()}`,
          accountId: String(accountId),
          loginStatus: userIsLoggedIn ? "Logged In" : "Logged Out",
          residenceState,
          balance: undefined
        }
      });
    });

export default onPageViewGTMEvent;
