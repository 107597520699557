import { attempt, get, isEmpty } from "lodash";
import { Dispatch } from "redux";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import uamServices from "@tvg/api/uam";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";

import {
  Feature,
  FeatureToggles,
  FeatureOverride,
  MapFeatureToggles,
  Messages,
  UamPreferencesResponse
} from "../../store/actions/types";
import {
  successMessagesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  failFeaturesRequest
} from "../../store/actions/capi";
import { failUserPrefs, setUserPrefs } from "../../store/actions/user";

export const getCapiMessages = (dispatch: Dispatch) => {
  const messageNamespaces = ["Homepage", "InformationalPages"];

  return tvgConf()
    .getMessages(messageNamespaces)
    .then((response: Messages) => dispatch(successMessagesRequest(response)))
    .catch((err: Error) => dispatch(failMessagesRequest(err)));
};

const getFeaturesOverrides = () =>
  attempt(
    () => JSON.parse(window.localStorage.getItem("featureOverrides")!),
    false
  );

const setFeaturesOverrides = (featureOverrides: FeatureOverride) =>
  attempt(() =>
    window.localStorage.setItem(
      "featureOverrides",
      JSON.stringify(featureOverrides)
    )
  );

export const setFeatureToggles = (
  features: Feature[],
  dispatch: Dispatch
): void => {
  const featureToggles: MapFeatureToggles = {};
  let featureOverrides: FeatureOverride = getFeaturesOverrides() || {};
  const hasFeaturesOverrides = !!getFeaturesOverrides();

  features.forEach((toggle: Feature) => {
    let { enabled } = toggle;
    featureOverrides = calculateFeatureOverride(
      hasFeaturesOverrides,
      featureOverrides,
      toggle
    );
    if (get(featureOverrides, toggle.name)) {
      // eslint-disable-next-line prefer-destructuring
      enabled = featureOverrides[toggle.name].enabled;
    }

    featureToggles[toggle.name] = enabled;
  });

  if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
    setFeaturesOverrides(featureOverrides);
  }
  dispatch(successFeaturesRequest(featureToggles));
};

export const getFeatures = (dispatch: Dispatch) =>
  typeof window !== "undefined" &&
  tvgConf()
    .getFeatures()
    .then((response: FeatureToggles | false) => {
      const featureToggles: MapFeatureToggles = {};
      let featureOverrides = getFeaturesOverrides() || {};
      const hasFeaturesOverrides = !!getFeaturesOverrides();

      if (response && Array.isArray(response.featureToggles)) {
        response.featureToggles.forEach((toggle: Feature) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );

          if (get(featureOverrides, toggle.name)) {
            // eslint-disable-next-line prefer-destructuring
            enabled = featureOverrides[toggle.name].enabled;
          }

          featureToggles[toggle.name] = enabled;
        });
      }

      if (!isEmpty(featureOverrides)) {
        setFeaturesOverrides(featureOverrides);
      }

      dispatch(successFeaturesRequest(featureToggles));
    })
    .catch((err: Error) => dispatch(failFeaturesRequest(err)));

export const getUserPreferences = (
  userAccountNumber: string,
  dispatch: Dispatch
) => {
  uamServices
    .getUserPrefs(userAccountNumber)
    .then((res: UamPreferencesResponse) => {
      const newPrefs: { [key: string]: string } = {};
      res.data.preferences.forEach((pref) => {
        if (pref.description === "bet_prefs") {
          newPrefs[pref.description] = JSON.parse(pref.metadata);
        } else {
          newPrefs[pref.description] = pref.metadata;
        }
      });

      // load default payment method to storage
      attempt(() =>
        localStorage.setItem(
          "defaultPaymentMethod",
          get(newPrefs, `["defaultPaymentMethod"]`, null)!
        )
      );

      dispatch(setUserPrefs(newPrefs, true));
      mediator.base.dispatch({ type: "USER_PREFS:LOAD_SUCCESS" });
    })
    .catch((err: Error) => {
      dispatch(failUserPrefs(err));
      mediator.base.dispatch({ type: "USER_PREFS:LOAD_FAIL" });
    });
};
