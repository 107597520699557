import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const DefaultBetContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const DefaultSection = styled.div<{ isMobileDevice?: boolean }>`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  padding: ${({ theme }) => theme.space["space-4"]};
  margin-top: ${({ theme }) => theme.space["space-4"]};
  display: flex;
  flex-direction: column;
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        padding: ${({ theme }) => theme.space["space-6"]};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
      }
    `};
`;

export const WagerSelectContainer = styled.div<{ isMobileDevice?: boolean }>`
  position: relative;
  margin-top: ${({ theme }) => theme.space["space-5"]};
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        width: 332px;
      }
    `};
`;

export const CustomBetListSection = styled.div`
  margin-top: ${({ theme }) => theme.space["space-3"]};
`;

export const BetGroupLine = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space["space-5"]};
`;
