import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationPreferencesToggle } from "@tvg/sh-lib-preferences/redux/selectors";
import type { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import { setSelectedTab } from "@tvg/sh-lib-preferences/redux/actions";

const useTabs = () => {
  const dispatch = useDispatch();
  const notificationPreferences = useSelector(getNotificationPreferencesToggle);

  const notificationTab = {
    title: "Communications",
    qaLabel: "notifications-tab",
    value: "NOTIFICATIONS"
  };

  const bettingTab = {
    title: "Betting",
    qaLabel: "betting-tab",
    value: "BETTING"
  };

  const accountTab = {
    title: "Account",
    qaLabel: "account-tab",
    value: "ACCOUNT"
  };

  const tabs = notificationPreferences
    ? [notificationTab, bettingTab, accountTab]
    : [bettingTab, accountTab];

  const defaultTab = (
    notificationPreferences ? "NOTIFICATIONS" : "BETTING"
  ) as PreferencesTabEnum;

  /* Set Betting tab selected on first render if notificationPreferences toggle is off */
  useEffect(() => {
    if (!notificationPreferences) {
      dispatch(setSelectedTab(defaultTab));
    }
  }, []);

  return {
    tabs,
    defaultTab,
    hasNotificationTab: notificationPreferences
  };
};

export default useTabs;
