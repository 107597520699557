import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// @ts-ignore
import { AppRegistry } from "react-native-web";
import tvgConf from "@tvg/conf";
import { TvgConfProvider, TVGThemeProviderWeb } from "@tvg/design-system";
import { FormationTokens } from "@tvg/design-system/web";
import Preferences from "@tvg/gtm/src/modules/Preferences";
import configureStore from "./store/configureStore";
import Main from "./pages";

const preloadedState = window.__PREFERENCES_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// GTM subscribers event init
Preferences();

const store = configureStore(preloadedState);
// gets conf for specific host / device
tvgConf(window.location.hostname, window.navigator.userAgent);

const App = () => (
  <TvgConfProvider>
    <TVGThemeProviderWeb isFanduelTheme={false}>
      <FormationTokens />
      <Provider store={store}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Provider>
    </TVGThemeProviderWeb>
  </TvgConfProvider>
);

const Render = () => {
  if (document.getElementById("preferences")) {
    AppRegistry.registerComponent("App", () => App);

    AppRegistry.runApplication("App", {
      initialProps: {},
      rootTag: document.getElementById("preferences")
    });
  }
};

export default Render();
