import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "node-fetch";
import TVGConf from "@tvg/conf";

export default (serverSide: boolean = false) => {
  const httpLink = createHttpLink({
    // @ts-ignore
    fetch,
    uri: TVGConf().config().service.graph
  });

  const retryLink = new RetryLink({
    attempts: () => true,
    delay: (count: number) => {
      if (count < 25) {
        return 2000 * Math.random();
      }
      return 15000;
    }
  });
  const link = retryLink.concat(httpLink);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    // @ts-ignore
    addTypename: true,
    ssrMode: serverSide
  });

  return { client };
};
