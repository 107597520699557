import styled, { css } from "styled-components";

const defaultContainer = css`
  margin-top: ${({ theme }) => theme.space["space-4"]};
  background-color: ${({ theme }) => theme.colorTokens.background.surface};

  @media only screen and (min-width: 600px) {
    margin: auto;
    max-width: 480px;
  }
`;

export const MaskContainer = styled.div`
  padding-right: ${({ theme }) => theme.space["space-4"]};
  ${defaultContainer};
`;

export const DefaultContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme, isMobile }) =>
    isMobile ? theme.space["space-4"] : theme.space["space-6"]};
  ${defaultContainer};
`;

export const BodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space["space-4"]};
`;

export const Text = styled.span`
  b {
    vertical-align: baseline;
  }
`;
