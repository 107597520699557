import styled, { css } from "styled-components";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  ${isTvg5() &&
  css`
    min-height: calc(100vh - 76px);
  `}
`;

export const TabContainer = styled.div<{ hasAlert: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  padding: ${({ theme, hasAlert }) =>
    hasAlert ? `0 0 ${theme.space["space-4"]}` : `${theme.space["space-4"]} 0`};

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 768px) {
    padding: ${({ theme }) => theme.space["space-4"]} 0;
    margin: 0 auto;
    max-width: 768px;
  }

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 768px) {
    padding: ${({ theme }) => theme.space["space-4"]} 160px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space["space-6"]} 160px;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: ${({ theme }) => theme.space["space-6"]};
  }
`;
