import mediator from "@tvg/mediator";

export const onGenericGtmEvent = ({
  gaEventAction,
  gaEventLabel,
  module
}: {
  gaEventAction: string;
  gaEventLabel: string | undefined;
  module: string;
}) =>
  mediator.base.dispatch({
    type: "PREF_UPDATE",
    payload: {
      gaEventAction,
      gaEventLabel,
      module
    }
  });
