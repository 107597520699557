import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import tvgConf from "@tvg/conf";
import { setNewPreferenceValue } from "@tvg/shared-actions/UserActions";
import {
  hideAlertPrefAction,
  openConfirmationModalPrefAction
} from "@tvg/sh-lib-preferences/redux/actions";
import {
  getAlertPrefs,
  getAlertsPrefsCenterMessages,
  getNotificationsPrefsCenterMessages,
  getNotificationsSwitches,
  getRaceAlertMtp,
  getShowMuteAllNotificationsPreferences,
  getMuteAllCommunications
} from "@tvg/sh-lib-preferences/redux/selectors";
import {
  AlertsCapi,
  NotificationsCapi,
  NotificationSwitch,
  PushStringTypes,
  RaceMtpOptions,
  SubscriptionGroupEnum
} from "@tvg/sh-lib-preferences/utils/types";
import {
  getSubscriptionAttributes,
  muteUnmuteAllNotifications,
  setAttribute,
  setStateMtpRaceAlert,
  setSubscriptionGroup,
  requireSubscriptionGroups
} from "@tvg/sh-lib-preferences/utils/notifications";
// @ts-ignore
import getUserPreferences from "@tvg/sh-lib-account-actions/src/utils/userPreferences";
import { getAccountNumber } from "@urp/store-selectors";
import { onGenericGtmEvent } from "../../utils/gtm/generic";
import { confirmationModalGtmEvent } from "../../utils/gtm/confirmModal";
import NotificationsPreferences from "./NotificationsPreferences";

export const NotificationsComponent = () => {
  const dispatch = useDispatch();

  const notificationsCapi: NotificationsCapi = useSelector(
    getNotificationsPrefsCenterMessages
  );
  const alertsCapi: AlertsCapi = useSelector(getAlertsPrefsCenterMessages);
  const showMuteAllNotificationsPreferences = useSelector(
    getShowMuteAllNotificationsPreferences
  );
  const muteAllCommunications = useSelector(getMuteAllCommunications);

  const notificationsSwitches = useSelector(getNotificationsSwitches);

  const accountNumber = useSelector(getAccountNumber);
  const alertState = useSelector(getAlertPrefs);
  const storeMtpRaceAlertsValue = useSelector(getRaceAlertMtp);

  const raceAlertsLabel = get(notificationsCapi, "raceAlerts.label", [
    "",
    "",
    ""
  ]);

  const [mtpRaceAlerts, setMtpRaceAlerts] = useState([
    {
      name: raceAlertsLabel[0],
      onClick: () => {
        setStateMtpRaceAlert(
          RaceMtpOptions.zero,
          mtpRaceAlerts,
          setMtpRaceAlerts,
          accountNumber,
          dispatch,
          setNewPreferenceValue("raceAlertMtp", RaceMtpOptions.zero)
        );
        onGenericGtmEvent({
          gaEventAction: "Race Alert Selected",
          gaEventLabel: raceAlertsLabel[0],
          module: "Notifications"
        });
      },
      enable: false
    },
    {
      name: raceAlertsLabel[1],
      onClick: () => {
        setStateMtpRaceAlert(
          RaceMtpOptions.two,
          mtpRaceAlerts,
          setMtpRaceAlerts,
          accountNumber,
          dispatch,
          setNewPreferenceValue("raceAlertMtp", RaceMtpOptions.two)
        );
        onGenericGtmEvent({
          gaEventAction: "Race Alert Selected",
          gaEventLabel: raceAlertsLabel[1],
          module: "Notifications"
        });
      },
      enable: false
    },
    {
      name: raceAlertsLabel[2],
      onClick: () => {
        setStateMtpRaceAlert(
          RaceMtpOptions.five,
          mtpRaceAlerts,
          setMtpRaceAlerts,
          accountNumber,
          dispatch,
          setNewPreferenceValue("raceAlertMtp", RaceMtpOptions.five)
        );
        onGenericGtmEvent({
          gaEventAction: "Race Alert Selected",
          gaEventLabel: raceAlertsLabel[2],
          module: "Notifications"
        });
      },
      enable: false
    }
  ]);

  const [isLoadingEmailOffers, setIsLoadingEmailOffers] = useState(false);
  const [isLoadingPushOffers, setIsLoadingPushOffers] = useState(false);
  const [isLoadingEmailPromo, setIsLoadingEmailPromo] = useState(false);
  const [isLoadingPushPromo, setIsLoadingPushPromo] = useState(false);
  const [isLoadingPushTalents, setIsLoadingPushTalents] = useState(false);
  const [isLoadingEmailOthers, setIsLoadingEmailOthers] = useState(false);
  const [isLoadingPushOthers, setIsLoadingPushOthers] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [isLoadingAttributes, setIsLoadingAttributes] = useState(true);

  const isNative =
    tvgConf().product === "iosnative" ||
    tvgConf().product === "androidnative" ||
    tvgConf().product === "androidwrapper" ||
    tvgConf().product === "tvgandroid";

  const toggleCheckboxGTM = (toggle: boolean, label: string) => {
    if (tvgConf().product === "tvg4") {
      onGenericGtmEvent({
        gaEventAction: toggle ? "Checkbox Checked" : "Checkbox Unchecked",
        gaEventLabel: label,
        module: "Notifications"
      });
    } else {
      onGenericGtmEvent({
        gaEventAction: toggle ? "Toggled On" : "Toggled Off",
        gaEventLabel: label,
        module: "Notifications"
      });
    }
  };

  const setIsLoading = (value: boolean) => {
    setIsLoadingSubscription(value);
    setIsLoadingAttributes(value);
  };

  useEffect(() => {
    if (accountNumber) {
      getUserPreferences(accountNumber, dispatch);
      getSubscriptionAttributes(
        dispatch,
        accountNumber,
        setIsLoadingAttributes,
        setIsLoadingSubscription
      );
    }

    return () => {
      dispatch(hideAlertPrefAction());
    };
  }, [accountNumber]);

  // To update the mtpRace alert preference when we get on redux store through storeMtpRaceAlertsValue
  useEffect(() => {
    setStateMtpRaceAlert(
      storeMtpRaceAlertsValue,
      mtpRaceAlerts,
      setMtpRaceAlerts
    );
  }, [storeMtpRaceAlertsValue]);

  useEffect(() => {
    if (alertState.type === "error-single") {
      requireSubscriptionGroups(
        accountNumber,
        setIsLoadingSubscription,
        dispatch
      );
    }
  }, [alertState]);

  return (
    <NotificationsPreferences
      notificationsCapi={notificationsCapi}
      alertsCapi={alertsCapi}
      alertState={alertState}
      isDesktop={tvgConf().device === "desktop"}
      isNative={isNative}
      mtpRaceAlerts={mtpRaceAlerts}
      isLoadingEmailOffers={isLoadingEmailOffers}
      isLoadingPushOffers={isLoadingPushOffers}
      isLoadingEmailPromo={isLoadingEmailPromo}
      isLoadingPushPromo={isLoadingPushPromo}
      isLoadingPushTalents={isLoadingPushTalents}
      isLoadingEmailOthers={isLoadingEmailOthers}
      isLoadingPushOthers={isLoadingPushOthers}
      isLoading={isLoadingAttributes || isLoadingSubscription}
      // Offers
      offersPush={notificationsSwitches.offersPush}
      setOffersPush={() => {
        setAttribute(
          dispatch,
          accountNumber,
          PushStringTypes.push_offers_and_news,
          !notificationsSwitches.offersPush,
          NotificationSwitch.push_offers_and_news,
          setIsLoadingPushOffers
        );
        toggleCheckboxGTM(
          !notificationsSwitches.offersPush,
          `${notificationsCapi.offersNews.title} - Push Notifications`
        );
      }}
      offersEmail={notificationsSwitches.offersEmail}
      setOffersEmail={() => {
        setSubscriptionGroup(
          dispatch,
          accountNumber,
          SubscriptionGroupEnum.email_offers_news,
          notificationsSwitches.offersEmail,
          NotificationSwitch.email_offers_news,
          setIsLoadingEmailOffers
        );
        toggleCheckboxGTM(
          !notificationsSwitches.offersEmail,
          `${notificationsCapi.offersNews.title} - Email`
        );
      }}
      // Promotions
      promoPush={notificationsSwitches.promoPush}
      setPromoPush={() => {
        setAttribute(
          dispatch,
          accountNumber,
          PushStringTypes.push_promotion_activity,
          !notificationsSwitches.promoPush,
          NotificationSwitch.push_promotion_activity,
          setIsLoadingPushPromo
        );
        toggleCheckboxGTM(
          !notificationsSwitches.promoPush,
          `${notificationsCapi.promotionActivity.title} - Push Notifications`
        );
      }}
      promoEmail={notificationsSwitches.promoEmail}
      setPromoEmail={() => {
        setSubscriptionGroup(
          dispatch,
          accountNumber,
          SubscriptionGroupEnum.email_promotion_activity,
          notificationsSwitches.promoEmail,
          NotificationSwitch.email_promotion_activity,
          setIsLoadingEmailPromo
        );
        toggleCheckboxGTM(
          !notificationsSwitches.promoEmail,
          `${notificationsCapi.promotionActivity.title} - Email`
        );
      }}
      // Talent Picks
      talentPicksPush={notificationsSwitches.talentPicksPush}
      setTalentPicksPush={() => {
        setAttribute(
          dispatch,
          accountNumber,
          PushStringTypes.talent_picks,
          !notificationsSwitches.talentPicksPush,
          NotificationSwitch.talent_picks,
          setIsLoadingPushTalents
        );
        toggleCheckboxGTM(
          !notificationsSwitches.talentPicksPush,
          `${notificationsCapi.talentPicks.title} - Push Notifications`
        );
      }}
      // Other Fanduel Communications
      otherFanduelPush={notificationsSwitches.otherFanduelPush}
      setOtherFanduelPush={() => {
        setAttribute(
          dispatch,
          accountNumber,
          PushStringTypes.push_fanduel_comms,
          !notificationsSwitches.otherFanduelPush,
          NotificationSwitch.push_fanduel_comms,
          setIsLoadingPushOthers
        );
        toggleCheckboxGTM(
          !notificationsSwitches.otherFanduelPush,
          `${notificationsCapi.other.title} - Push Notifications`
        );
      }}
      otherFanduelEmail={notificationsSwitches.otherFanduelEmail}
      setOtherFanduelEmail={() => {
        setSubscriptionGroup(
          dispatch,
          accountNumber,
          SubscriptionGroupEnum.email_fanduel_comms,
          notificationsSwitches.otherFanduelEmail,
          NotificationSwitch.email_fanduel_comms,
          setIsLoadingEmailOthers
        );
        toggleCheckboxGTM(
          !notificationsSwitches.otherFanduelEmail,
          `${notificationsCapi.other.title} - Email`
        );
      }}
      showMuteAllNotificationsPreferences={showMuteAllNotificationsPreferences}
      muteAllCommunications={muteAllCommunications}
      handleResetNotifications={() => {
        dispatch(openConfirmationModalPrefAction("NOTIFICATIONS"));
        confirmationModalGtmEvent(true, true);
      }}
      unmuteAllCommunications={() => {
        muteUnmuteAllNotifications(
          dispatch,
          accountNumber,
          false,
          setIsLoading
        );
        onGenericGtmEvent({
          gaEventAction: "Notifications Unmuted",
          gaEventLabel: undefined,
          module: "Communications"
        });
      }}
    />
  );
};

export default NotificationsComponent;
