import React from "react";

import Preferences from "@tvg/pref";
import Containers from "@tvg/atomic-ui/_static/BaseComponents/Containers";

const EmailUnsubscribe = () => (
  <Containers.Page isFullHeight>
    <Preferences showUnsubscribe />
  </Containers.Page>
);

export default EmailUnsubscribe;
