import React from "react";
import styled, { keyframes, css } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  display: flex;
  flex-direction: column;
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  flex-grow: 1;
`;

const CardContainer = styled.div<{ hasBorderTop?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space["space-4"]};
  }

  ${({ hasBorderTop, theme }) =>
    hasBorderTop &&
    css`
      border-top: 1px solid ${theme.colorTokens.border.default};
    `}
`;

const Card = () => (
  <CardContainer>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="136"
      viewBox="0 0 375 136"
      fill="none"
      preserveAspectRatio="none"
    >
      <g filter="url(#filter0_i_3620_26014)">
        <rect width="375" height="136" fill="white" />
        <rect x="12" y="12" width="87" height="16" rx="1" fill="#E9EFF5" />
        <rect x="12" y="36" width="283" height="16" rx="1" fill="#E9EFF5" />
        <rect x="12" y="64" width="351" height="32" rx="1" fill="#E9EFF5" />
        <rect x="12" y="108" width="351" height="16" rx="1" fill="#E9EFF5" />
      </g>
      <defs>
        <filter
          id="filter0_i_3620_26014"
          x="0"
          y="0"
          width="375"
          height="136"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3620_26014"
          />
        </filter>
      </defs>
    </SVG>
  </CardContainer>
);

const ToggleCard = () => (
  <CardContainer>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="172"
      viewBox="0 0 375 172"
      fill="none"
      preserveAspectRatio="none"
    >
      <g filter="url(#filter0_i_3620_26026)">
        <rect width="375" height="172" fill="white" />
        <rect x="12" y="12" width="87" height="16" rx="1" fill="#E9EFF5" />
        <rect x="12" y="36" width="330" height="16" rx="1" fill="#E9EFF5" />
        <rect x="12" y="60" width="266" height="16" rx="1" fill="#E9EFF5" />
        <g filter="url(#filter1_i_3620_26026)">
          <rect
            width="351"
            height="36"
            transform="translate(12 88)"
            fill="white"
          />
          <rect x="12" y="92" width="170" height="16" rx="1" fill="#E9EFF5" />
          <rect x="311" y="88" width="52" height="24" rx="12" fill="#E9EFF5" />
        </g>
        <rect
          width="351"
          height="24"
          transform="translate(12 136)"
          fill="white"
        />
        <rect x="12" y="140" width="170" height="16" rx="1" fill="#E9EFF5" />
        <rect x="311" y="136" width="52" height="24" rx="12" fill="#E9EFF5" />
      </g>
      <defs>
        <filter
          id="filter0_i_3620_26026"
          x="0"
          y="0"
          width="375"
          height="172"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3620_26026"
          />
        </filter>
        <filter
          id="filter1_i_3620_26026"
          x="12"
          y="88"
          width="351"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3620_26026"
          />
        </filter>
      </defs>
    </SVG>
  </CardContainer>
);

const Loading = () => (
  <Container>
    <Card />
    <ToggleCard />
    <ToggleCard />
    <Card />
  </Container>
);

export default Loading;
