import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  getAlertPrefs,
  getNotificationPreferencesToggle,
  getSelectedTab
} from "@tvg/sh-lib-preferences/redux/selectors";
import { setSelectedTab } from "@tvg/sh-lib-preferences/redux/actions";
import tvgConf from "@tvg/conf";
import { getAccountNumber, getResidenceState } from "@urp/store-selectors";
import ConfirmModal from "./components/ConfirmModal";
import EmailUnsubscribe from "./components/Unsubscribe";
import onPageViewGTMEvent from "./utils/gtm/pageView";
import PreferencesCenter from "./components/PreferencesCenter";
import useTabs from "./hooks/useTabs";
import useTabSelection from "./hooks/useTabSelection";

interface Props {
  showUnsubscribe: boolean;
}

export const Preferences = ({ showUnsubscribe }: Props) => {
  const dispatch: Dispatch = useDispatch();
  const selectedTab = useSelector(getSelectedTab);
  const notificationPreferences = useSelector(getNotificationPreferencesToggle);
  const accountId = useSelector(getAccountNumber);
  const residenceState = useSelector(getResidenceState);
  const alertState = useSelector(getAlertPrefs);
  const { tabs, defaultTab, hasNotificationTab } = useTabs();
  const onSelectTab = useTabSelection();

  useEffect(() => {
    if (
      tvgConf().product === "tvg4" &&
      !!accountId &&
      !!residenceState &&
      !showUnsubscribe
    ) {
      onPageViewGTMEvent({
        accountId,
        selectedTab: notificationPreferences ? "notifications" : "betting",
        residenceState
      });
    }
  }, [accountId, residenceState]);

  useEffect(() => {
    dispatch(
      setSelectedTab(notificationPreferences ? "NOTIFICATIONS" : "BETTING")
    );
  }, [notificationPreferences]);

  if (showUnsubscribe) {
    return <EmailUnsubscribe />;
  }

  return (
    <>
      <PreferencesCenter
        onSelect={(tab) => onSelectTab(tab, selectedTab)}
        selectedTab={selectedTab || defaultTab}
        tabs={tabs}
        hasAlert={alertState.show}
        hasNotificationTab={hasNotificationTab}
      />
      <ConfirmModal />
    </>
  );
};

export default Preferences;
