import React, { memo, forwardRef, useState, useEffect } from "react";
import { TextInput } from "react-native";
import { useQaLabel } from "../../../hooks";
import { useEvents } from "../../../hooks/useEvents";
import { useTheme } from "../../../theming";
import type { InputSelectProps } from "../types";
import { InputWrapper, TopWrapper, InputBox } from "../styled-components";
import { InputLabel } from "../inputLabel";
import { InputMessage } from "../inputMessage";
import { Icon } from "../../icon";
import { SelectIconWrapper, Select, Option } from "./styled-components";

export const InputSelect = forwardRef<TextInput, InputSelectProps>(
  (
    {
      placeholder,
      label = "",
      messageAbove = "",
      messageBellow = "",
      isInvalid = false,
      isOptional = false,
      isDisabled = false,
      warningMessage = "",
      qaLabel,
      selectList,
      onChange,
      selectedValue = "",
      ...rest
    },
    ref
  ) => {
    const [listWithPlaceholder, setListWithPlaceholder] = useState(selectList);
    const [isFocused, setIsFocused] = useState(false);
    const { events, hoverHandlers } = useEvents({});
    const { colors, fonts, fontSizes } = useTheme();

    useEffect(() => {
      if (placeholder) {
        setListWithPlaceholder([
          { label: placeholder, value: "" },
          ...selectList
        ]);
      } else {
        setListWithPlaceholder(selectList);
      }
    }, [selectList, placeholder]);

    const handleChangeWeb = (e: MouseEvent) => {
      // @ts-ignore
      const { value } = e.target;
      // eslint-disable-next-line
      onChange && onChange(value);
    };

    return (
      <InputWrapper {...rest} {...useQaLabel(qaLabel)}>
        <TopWrapper>
          <InputLabel label={label} isOptional={isOptional} />
        </TopWrapper>
        {messageAbove !== "" && (
          <InputMessage message={messageAbove} marginTop="space-3" />
        )}
        <InputBox
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          editable={true}
          isSelect={true}
          {...rest}
          {...hoverHandlers}
          {...events}
          isFocused={isFocused}
        >
          <Select
            ref={ref}
            colors={colors}
            fonts={fonts}
            fontsizes={fontSizes}
            disabled={isDisabled}
            onChange={handleChangeWeb}
            value={selectedValue as string}
            onFocus={() => setIsFocused(true)}
            onMouseOut={() => setIsFocused(false)}
            {...useQaLabel(`${qaLabel}-select`)}
          >
            {listWithPlaceholder &&
              listWithPlaceholder.map((item) => (
                <Option
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  fonts={fonts}
                  disabled={item.value === "" || item.value == null}
                >
                  {item.label}
                </Option>
              ))}
          </Select>
          <SelectIconWrapper isDisabled={isDisabled}>
            <Icon
              size="s"
              qaLabel="chevron-down-icon"
              lineColor={isDisabled ? "blue_accent.300" : "blue_accent.500"}
              name="chevronDown"
            />
          </SelectIconWrapper>
        </InputBox>
        {isInvalid && warningMessage && (
          <InputMessage
            message={warningMessage}
            isWarning={isInvalid}
            mx={0}
            mt={2}
            mb="space-3"
          />
        )}
        {messageBellow !== "" && <InputMessage message={messageBellow} />}
      </InputWrapper>
    );
  }
);

export default memo(InputSelect);
