import Generic from "./Generic";
import Header from "./Header";
import PageView from "./PageView";

const Preferences = () => {
  Generic();
  Header();
  PageView();
};

export default Preferences;
