import React, { useState, useEffect } from "react";
import { LayoutChangeEvent, Dimensions } from "react-native";
import { ScrollViewFadingProps, ScrollEventType } from "./types";
import { StyledScrollView } from "./styled-components";
import { Fading } from "./fading";
import { useQaLabel } from "../../hooks";

export const ScrollViewFading = ({
  children,
  showScrollBars = true,
  paddingAround = false
}: ScrollViewFadingProps) => {
  const [showFadeStart, setShowFadeStart] = useState(false);
  const [showFadeEnd, setShowFadeEnd] = useState(false);
  const [componentWidth, setComponentWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const scrollPadding = 20;

  const windowResizeHandler = () => {
    setScreenWidth(Dimensions.get("window").width);
  };

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    Dimensions.addEventListener("change", windowResizeHandler);
    return () => {
      // @ts-ignore
      Dimensions.removeEventListener("change", windowResizeHandler);
    };
  }, []);

  useEffect(() => {
    setShowFadeEnd(componentWidth === screenWidth);
  }, [componentWidth, screenWidth]);

  const scrollIsCloseToEnd = ({
    layoutMeasurement,
    contentOffset,
    contentSize
  }: ScrollEventType) =>
    layoutMeasurement.width + contentOffset.x >=
    contentSize.width - scrollPadding;

  const scrollIsCloseToStart = (nativeEvent: ScrollEventType) =>
    nativeEvent.contentOffset.x <= scrollPadding;

  const scrollHandler = ({ nativeEvent }: { nativeEvent: ScrollEventType }) => {
    setShowFadeEnd(!scrollIsCloseToEnd(nativeEvent));
    setShowFadeStart(!scrollIsCloseToStart(nativeEvent));
  };

  const onLayoutHandler = (event: LayoutChangeEvent) => {
    setComponentWidth(event.nativeEvent.layout.width);
  };

  return (
    <>
      <Fading position="start" isVisible={showFadeStart} />
      <StyledScrollView
        onLayout={onLayoutHandler}
        {...useQaLabel("scrollview")}
        horizontal
        onScroll={scrollHandler}
        showsVerticalScrollIndicator={showScrollBars}
        showsHorizontalScrollIndicator={showScrollBars}
        scrollEventThrottle={200}
        paddingAround={paddingAround}
      >
        {children}
      </StyledScrollView>
      <Fading position="end" isVisible={showFadeEnd} />
    </>
  );
};
