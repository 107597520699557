import { useCallback, useEffect } from "react";
import queryString from "query-string";

import { EmailParams, SubscriptionGroupEnum, UnaryFn } from "../utils/types";

const useEmailUnsubscribe = (
  searchParams: string,
  setEmailParams: UnaryFn<EmailParams, void>,
  setShowFallbackScreen: UnaryFn<boolean, void>
) => {
  const params = useCallback(
    () => queryString.parse(searchParams),
    [searchParams]
  );

  useEffect(() => {
    if (searchParams === "") {
      setShowFallbackScreen(true);
    } else {
      const urlParams = params();
      const category = urlParams?.category;
      const email = urlParams?.email;
      const accountId = urlParams?.accountId;

      if (
        Object.values(SubscriptionGroupEnum).includes(
          category as SubscriptionGroupEnum
        ) &&
        !!email &&
        !!accountId
      ) {
        // @ts-ignore
        setEmailParams(urlParams);
      } else {
        setShowFallbackScreen(true);
      }
    }
  }, []);
};

export default useEmailUnsubscribe;
