import { formatNumber } from "@tvg/formatter/currency";
import { forEach } from "lodash";
import type {
  GraphWagerType,
  PreferencesBetAmount,
  WagerGroups,
  WagerGroupsItem
} from "./types";

/*
  100 Quinella Wheel
  130 Exacta Wheel
  180 Trifecta Wheel
  230 Superfecta Wheel
  280 Superhighfive Wheel
  880 Omni Wheel
  910 Trio Wheel
 */
const notAllowedBets = [100, 130, 180, 230, 280, 880, 910];

const mainWagerGroups: { [key in string]: string } = {
  "10": "win",
  "20": "place",
  "30": "show",
  "110": "exacta",
  "160": "trifecta",
  "210": "superfecta",
  "260": "superhighfive",
  P: "pick"
};

export const keyDown = (e: {
  keyCode: number;
  preventDefault: () => void;
  stopPropagation: () => void;
}) => {
  // to not accept period for introduced value
  if (e.keyCode === 190) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const filterNotAllowedBets = (
  bets: GraphWagerType[]
): GraphWagerType[] => {
  const wagerTypes = bets.filter(
    (wt) => notAllowedBets.indexOf(wt.type.id) === -1
  );
  wagerTypes.unshift({ type: { name: "No default bet type", id: 0 } });
  return wagerTypes;
};

export const getWagerGroupsValues = (
  wagerGroups: WagerGroups
): Array<WagerGroupsItem> => {
  const arr: Array<WagerGroupsItem> = [];
  forEach(wagerGroups, (value) => arr.push(value));
  return arr;
};

export const defaultBetPrefs = {
  defaultBetType: "0",
  useMinimumBetAmount: true,
  useCustomBetAmount: false,
  default_bet_amount: {
    "10": "",
    "20": "",
    "30": "",
    "110": "",
    "160": "",
    "210": "",
    "260": "",
    P: ""
  }
};

export const setWagerCategoriesToState = (
  wagerGroups: PreferencesBetAmount
): WagerGroups => {
  let wagers = wagerGroups;
  if (!wagerGroups || Object.keys(wagerGroups).length === 0) {
    wagers = defaultBetPrefs.default_bet_amount;
  }

  const betAmountsState: WagerGroups = {
    "10": {
      group: "",
      title: "",
      value: ""
    },
    "110": {
      group: "",
      title: "",
      value: ""
    },
    "160": {
      group: "",
      title: "",
      value: ""
    },
    "20": {
      group: "",
      title: "",
      value: ""
    },
    "210": {
      group: "",
      title: "",
      value: ""
    },
    "260": {
      group: "",
      title: "",
      value: ""
    },
    "30": {
      group: "",
      title: "",
      value: ""
    },
    P: {
      group: "",
      title: "",
      value: ""
    }
  };

  Object.keys(mainWagerGroups).forEach((group) => {
    // @ts-ignore
    betAmountsState[group] = {
      group,
      title: mainWagerGroups[group],
      // @ts-ignore
      value: wagers[group] ? formatNumber(wagers[group]) : ""
    };
  });
  return betAmountsState;
};

export const formatBetAmountsForService = (
  wagerGroups: WagerGroups
): WagerGroups => {
  const prefCat: WagerGroups = {
    "10": {
      group: "",
      title: "",
      value: ""
    },
    "110": {
      group: "",
      title: "",
      value: ""
    },
    "160": {
      group: "",
      title: "",
      value: ""
    },
    "20": {
      group: "",
      title: "",
      value: ""
    },
    "210": {
      group: "",
      title: "",
      value: ""
    },
    "260": {
      group: "",
      title: "",
      value: ""
    },
    "30": {
      group: "",
      title: "",
      value: ""
    },
    P: {
      group: "",
      title: "",
      value: ""
    }
  };
  Object.keys(wagerGroups).forEach((category) => {
    // @ts-ignore
    prefCat[category] = wagerGroups[category].value;
  });
  return prefCat;
};
