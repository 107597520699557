// @flow
import type { UserInfo } from "@tvg/types/User";
import { type UserOptedInPromos } from "@tvg/types/Login";
import type {
  SetNewPreferenceValue,
  UpdateBalanceActionType,
  UpdatePromoFundsActionType,
  SessionStartUpdate
} from "@tvg/shared-actions/UserActions";
import type { UserJurisdiction } from "@fdr/types/User";

export type UserData = {
  error: boolean | null,
  hasRequested: boolean,
  logged: boolean,
  logging: boolean,
  user: UserInfo,
  wasLogin: boolean,
  userLogin?: boolean,
  userLogout?: boolean,
  loginPin?: number,
  tvg3token?: string
};

export type PreferencesBetAmount = {
  WN: string,
  PL: string,
  SH: string,
  EX: string,
  TR: string,
  SU: string,
  SH5: string,
  P: string
};

export type UserBetPrefs = {
  defaultBetType: string,
  useCustomBetAmount: boolean,
  useMinimumBetAmount: boolean,
  default_bet_amount: PreferencesBetAmount
};

export type FailBalanceRequestAction = {
  type: "USER_BALANCE_FAIL",
  payload: Error,
  error: true
};

export type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE",
  payload: UserData
};

export type UserLogoutAction = {
  type: "USER_LOGOUT",
  payload: { logged: boolean }
};

export type UserPrefsSet = {
  type: "USER_PREFS_SET",
  payload: {
    preferences: mixed,
    gotPreferences: boolean
  }
};

export type UserPrefsFail = {
  type: "USER_PREFS_FAIL",
  payload: {},
  error: true
};

export type UserPromo = {
  id: number,
  optedIn: boolean,
  path: string
};

export type UserPromosSuccess = {
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: UserOptedInPromos }
};

export type UserPromosLoading = {
  type: "USER_PROMOS_LOADING"
};

export type UserPromosClear = {
  type: "USER_PROMOS_CLEAR"
};

export type UserPromosFail = {
  type: "USER_PROMOS_FAIL",
  payload: {},
  error: true
};

export type UpdateBalancePollerAction = {
  type: "USER_BALANCE_POLLER",
  payload: number
};

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged: boolean
  }
};

export type SetUserVerifiedState = {
  type: "SET_IS_VERIFIED",
  payload: {
    isVerified: boolean
  }
};

export type SetUserJurisdiction = {
  type: "SET_USER_JURISDICTION",
  payload: {
    jurisdiction: UserJurisdiction
  }
};

export type UpdateWalletBalancesAction = {
  type: "USER_WALLET_BALANCES_SUCCESS",
  payload: {
    balance: number,
    bonus: number,
    sharedDeposits: number,
    sharedWinnings: number
  }
};

export type SetReturningUserState = {
  type: "USER_RETURNING_STATE",
  payload: {
    returningUser: boolean
  }
};

export type SetUserAccountNumber = {
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber: string
  }
};

export type UserPrefsBalanceShownAction = {
  type: "USER_PREFS_BALANCE_SHOWN",
  payload: {
    balance_is_shown: boolean
  }
};

export type UserDefaultBetPrefsAction = {
  type: "SET_DEFAULT_BET_PREFERENCES",
  payload: UserBetPrefs
};

export type UserPrefsBetConfirmationAction = {
  type: "USER_PREFS_BET_CONFIRMATION",
  payload: {
    balance_is_shown: boolean
  }
};

export type SuccessBrazePollerRequestAction = {
  type: "BRAZE_TIMER_POLLER",
  payload: number
};

export type LoginErrorPageAction = {
  type: "LOGIN_ERROR_PAGE",
  payload: {
    isLoginError: boolean
  }
};

export type UserActions =
  | UserSessionSuccessAction
  | UpdateBalanceActionType
  | UpdatePromoFundsActionType
  | UpdateBalancePollerAction
  | FailBalanceRequestAction
  | SetUserLoggedState
  | SetReturningUserState
  | UserDefaultBetPrefsAction
  | SetUserAccountNumber
  | SetUserVerifiedState
  | SetUserJurisdiction
  | UserLogoutAction
  | UserPrefsSet
  | UserPrefsFail
  | UserPrefsBalanceShownAction
  | UserPrefsBetConfirmationAction
  | UserPromosSuccess
  | UserPromosLoading
  | UserPromosFail
  | UserPromosClear
  | SetNewPreferenceValue
  | UpdateWalletBalancesAction
  | SuccessBrazePollerRequestAction
  | LoginErrorPageAction
  | SessionStartUpdate;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const successBalancePollerRequest = (
  balancePoller: number
): UpdateBalancePollerAction => ({
  type: "USER_BALANCE_POLLER",
  payload: balancePoller
});

export const failBalanceRequest = (err: Error): FailBalanceRequestAction => ({
  type: "USER_BALANCE_FAIL",
  payload: err,
  error: true
});

export const setUserPrefs = (
  userPrefs: mixed = {},
  gotPreferences: boolean = false
): UserPrefsSet => ({
  type: "USER_PREFS_SET",
  payload: {
    preferences: userPrefs,
    gotPreferences
  }
});

export const failUserPrefs = (err: Error): UserPrefsFail => ({
  type: "USER_PREFS_FAIL",
  payload: err,
  error: true
});

export const successUserPromos = (
  userPromos: UserOptedInPromos
): UserPromosSuccess => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});

export const clearUserPromos = (): UserPromosClear => ({
  type: "USER_PROMOS_CLEAR"
});

export const loadingUserPromos = (): UserPromosLoading => ({
  type: "USER_PROMOS_LOADING"
});

export const failUserPromos = (err: Error): UserPromosFail => ({
  type: "USER_PROMOS_FAIL",
  payload: err,
  error: true
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});

export const setIsLoginErrorPage = (
  isLoginError: boolean
): LoginErrorPageAction => ({
  type: "LOGIN_ERROR_PAGE",
  payload: {
    isLoginError
  }
});
