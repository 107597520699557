import React, { useEffect } from "react";
// @ts-ignore
import { Route, Routes, RouteComponentProps } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { useSelector, useDispatch } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { isEmpty } from "lodash";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { getStoreFeatures } from "../store/selectors";
import { Feature } from "../store/actions/types";
import MicroApp from "./components/MicroApp";
import EmailSubscribe from "./components/EmailUnsubscribe";
import ApolloClient from "../apolloClient/ApolloClient";
import {
  dispatchUserPrefs,
  fetchUserData,
  getUserBalance,
  updateTVG4Router,
  updateRouter
} from "./utils/callbacks";
import {
  getCapiMessages,
  getFeatures,
  getUserPreferences,
  setFeatureToggles
} from "./utils/helpers";

type Props = {
  history: RouteComponentProps["history"];
};

const Main = ({ history }: Props) => {
  const isLogged: boolean = useSelector(getIsLogged);
  const accountNumber: string = useSelector(getAccountNumber);
  const features: Feature[] = useSelector(getStoreFeatures);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== "undefined") {
      mediatorClassic.subscribe("UPDATE_ROUTER", updateRouter(history));
      mediator.base.subscribe("TVG4_NAVIGATION", updateTVG4Router);
      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        fetchUserData(dispatch)
      );
      mediatorClassic.subscribeWithPast(
        "USER_PREFS_SET",
        dispatchUserPrefs(dispatch)
      );
      mediatorClassic.subscribeWithPast(
        "BALANCE_UPDATE",
        getUserBalance(dispatch)
      );

      if (!isEmpty(features)) {
        setFeatureToggles(features, dispatch);
      } else {
        getFeatures(dispatch);
      }

      // Update namespace if required to pull specific
      // messages from capi within helper utility
      getCapiMessages(dispatch);
    }

    return () => {
      if (typeof window !== "undefined") {
        mediatorClassic.unsubscribe("UPDATE_ROUTER", updateRouter);
        mediator.base.unsubscribe("TVG4_NAVIGATION", updateTVG4Router);
        mediatorClassic.unsubscribe(
          "TVG_LOGIN:USER_SESSION_UPDATE",
          fetchUserData
        );
        mediatorClassic.unsubscribe("USER_PREFS_SET", dispatchUserPrefs);
        mediatorClassic.unsubscribe("BALANCE_UPDATE", getUserBalance);
      }
    };
  }, []);

  useEffect(() => {
    if (accountNumber) {
      getUserPreferences(accountNumber, dispatch);
    }
  }, [isLogged, accountNumber]);

  return (
    <main>
      <TvgSafeAreaProvider>
        <ApolloProvider client={ApolloClient().client}>
          <Routes>
            <Route path="/preferences" element={<MicroApp />} />
            <Route path="/unsubscribe" element={<EmailSubscribe />} />
          </Routes>
        </ApolloProvider>
      </TvgSafeAreaProvider>
    </main>
  );
};

export default withRouter(Main);
