import { gql } from "@apollo/client";

export const GET_ALL_WAGER_TYPES = gql`
  query GetAllWagerTypes {
    wagerTypes(sort: { byId: ASC }) {
      type {
        id
        name
      }
    }
  }
`;

export default GET_ALL_WAGER_TYPES;
