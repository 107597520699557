import React from "react";
import { TextInput, Platform } from "react-native";
import type { InputFieldProps } from "./types";
import { InputBox, StyledInput, StyledPressable } from "./styled-components";
import { useEvents } from "../../hooks/useEvents";
import { useQaLabel } from "../../hooks";

export const InputField = React.forwardRef(
  (
    {
      type = "text",
      editable = true,
      isInvalid = false,
      isDisabled = false,
      maxLength = 250,
      start,
      end,
      qaLabel,
      ...rest
    }: InputFieldProps,
    ref: React.Ref<TextInput>
  ) => {
    const inputRef = React.useRef<TextInput>(null);
    const { events, hoverHandlers, pressHandlers, focusHandlers } = useEvents(
      {},
      !isDisabled
    );

    return (
      <InputBox
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        editable={editable}
        {...rest}
        {...events}
        {...hoverHandlers}
        {...useQaLabel(qaLabel)}
      >
        {start}
        <StyledPressable
          {...pressHandlers}
          onFocus={() =>
            (ref && typeof ref !== "function" && ref.current?.focus()) ||
            inputRef?.current?.focus()
          }
        >
          <StyledInput
            ref={ref || inputRef}
            secureTextEntry={type === "password"}
            editable={editable && !isDisabled}
            isDisabled={isDisabled}
            isNested
            maxLength={maxLength}
            {...rest}
            {...focusHandlers}
            {...(Platform.OS === "web"
              ? {
                  disabled: isDisabled
                }
              : {})}
          />
        </StyledPressable>
        {end}
      </InputBox>
    );
  }
);
