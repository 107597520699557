// @flow

import React from "react";
import styled, { keyframes } from "styled-components";

import buildColor from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "000")};
  }

  50% {
    fill: ${buildColor("blue", "100")};
  }

  100% {
    fill: ${buildColor("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 244px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const EmailUnsubscribeBodyMask = () => (
  <div data-qa-label="emailUnsubscribeBodyMask">
    <SVG>
      <g>
        <rect className="pulse" x="12" y="136" width="351" height="44" rx="1" />
        <rect className="pulse" x="12" y="188" width="351" height="44" rx="1" />
        <rect className="pulse" x="12" y="48" width="337" height="16" rx="1" />
        <rect className="pulse" x="12" y="96" width="275" height="16" rx="1" />
        <rect className="pulse" x="12" y="72" width="313" height="16" rx="1" />
        <rect className="pulse" x="12" y="12" width="154" height="16" rx="1" />
      </g>
    </SVG>
  </div>
);

// $FlowFixMe
export default React.memo(EmailUnsubscribeBodyMask);
