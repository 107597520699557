// @flow
import { type Withdrawal } from "@tvg/atomic-ui/_templates/Withdrawals";
import { type SetWithdrawalsAction } from "@tvg/withdrawals/src/actions";
import { type TutorialListFromService } from "@tvg/types/OnBoarding";

type PreferenceMetadataEnum = "0" | "1";

type BalanceShownAction = {
  type: "USER_PREFS_BALANCE_SHOWN",
  payload: PreferenceMetadataEnum
};

type SetBetConfirmationPreference = {
  type: "USER_PREFS_BET_CONFIRMATION",
  payload: PreferenceMetadataEnum
};

type iOSTouchIdToggleAction = {
  type: "IOS_TOUCH_ID_TOGGLE",
  payload: boolean
};

type iOSTouchIdToggleChangeAction = {
  type: "IOS_TOUCH_ID_CHANGE",
  payload: {
    isChanging: boolean
  }
};

type SetTutorialsList = {
  type: "SET_TUTORIALS_LIST",
  payload: TutorialListFromService[]
};

export type Actions =
  | BalanceShownAction
  | SetBetConfirmationPreference
  | iOSTouchIdToggleAction
  | iOSTouchIdToggleChangeAction
  | SetWithdrawalsAction
  | SetTutorialsList;

export const balanceShownAction = (
  isBalanceShown: PreferenceMetadataEnum
): BalanceShownAction => ({
  type: "USER_PREFS_BALANCE_SHOWN",
  payload: isBalanceShown
});

export const setBetConfirmationPreference = (
  isBetConfirmationEnabled: PreferenceMetadataEnum
): SetBetConfirmationPreference => ({
  type: "USER_PREFS_BET_CONFIRMATION",
  payload: isBetConfirmationEnabled
});

export const setTutorialsListAction = (
  tutorialsList: TutorialListFromService[]
): SetTutorialsList => ({
  type: "SET_TUTORIALS_LIST",
  payload: tutorialsList
});

export const iOSTouchIdToggle = (payload: boolean): iOSTouchIdToggleAction => ({
  type: "IOS_TOUCH_ID_TOGGLE",
  payload
});

export const setWithdrawals = (
  withdrawals: Withdrawal[]
): SetWithdrawalsAction => ({
  type: "WITHDRAWALS_SET_WITHDRAWS",
  payload: { withdrawals }
});

export const iOSTouchIdToggleChange = (
  isChanging: boolean
): iOSTouchIdToggleChangeAction => ({
  type: "IOS_TOUCH_ID_CHANGE",
  payload: { isChanging }
});
