// @ts-ignore
import type { OperationComponent } from "@apollo/client";
import { attempt } from "lodash/fp";

import type { GraphResponse } from "../utils/types";

const getProps = (result: GraphResponse) => {
  const props = {};
  attempt(() => {
    Object.assign(props, {
      availableWagerTypes: result?.wagerTypes ? result.wagerTypes : []
    });
  });

  return props;
};

export default {
  options: () => ({
    fetchPolicy: "cache-and-network",
    variables: { sort: { byId: "ASC" } },
    ssr: false
  }),
  props: (result: OperationComponent<GraphResponse>) => ({
    ...getProps(result.data)
  })
};
