import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  display: flex;
  flex-direction: column;
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  flex-grow: 1;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space["space-4"]};
  }
`;

const Card = () => (
  <CardContainer>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="118"
      viewBox="0 0 705 118"
      fill="none"
      preserveAspectRatio="none"
    >
      <g clipPath="url(#clip0_6336_12038)">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H701C703.209 0 705 1.79086 705 4V70H0V4Z"
          fill="white"
        />
        <rect x="20" y="22" width="351" height="16" rx="1" fill="#E9EFF5" />
        <rect x="20" y="44" width="665" height="12" rx="1" fill="#E9EFF5" />
        <g filter="url(#filter0_i_6336_12038)">
          <path
            d="M0 70H705V114C705 116.209 703.209 118 701 118H4.00001C1.79087 118 0 116.209 0 114V70Z"
            fill="white"
          />
          <g filter="url(#filter1_d_6336_12038)">
            <rect x="20" y="78" width="130" height="24" rx="4" fill="#E9EFF5" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_6336_12038"
          x="0"
          y="70"
          width="705"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_6336_12038"
          />
        </filter>
        <filter
          id="filter1_d_6336_12038"
          x="18"
          y="77"
          width="134"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.913725 0 0 0 0 0.937255 0 0 0 0 0.960784 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6336_12038"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6336_12038"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_6336_12038">
          <rect width="705" height="118" rx="4" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  </CardContainer>
);

const Loading = () => (
  <Container>
    <Card />
    <Card />
    <Card />
  </Container>
);

export default Loading;
