import mediator from "@tvg/mediator";

interface TogglePreferenceEvent {
  module: "betting" | "account";
  setting: "bet_confirmation" | "balance_visibility";
  value: "0" | "1";
}

const onTogglePreference = ({
  module,
  setting,
  value
}: TogglePreferenceEvent) =>
  mediator.base.dispatch({
    type: "BET_PREFERENCES:TOGGLE_PREFERENCE",
    payload: {
      module,
      setting,
      itemSelected: value === "0" ? "off" : "on"
    }
  });

export default onTogglePreference;
