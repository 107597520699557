import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import PreferenceService from "@tvg/api/uam";
import {
  Actions,
  balanceShownAction,
  iOSTouchIdChangeAction,
  PreferenceMetadataEnum
} from "@tvg/sh-lib-preferences/redux/actions";
import {
  getAccountPrefsCenterMessages,
  getBalanceIsShown,
  getBiometricToggleState,
  getBiometricType
} from "@tvg/sh-lib-preferences/redux/selectors";
import { ToggleStateEnum } from "@tvg/sh-lib-preferences/utils/types";
import { getAccountNumber } from "@urp/store-selectors";
import { onGenericGtmEvent } from "../../utils/gtm/generic";
import ToggleGroup from "../ToggleGroup";
import onToggleBalanceVisibility from "../../utils/gtm/togglePreference";

const AccountComponent = () => {
  const dispatch: Dispatch<Actions> = useDispatch();
  const accountNumber = useSelector(getAccountNumber);
  const balanceIsShown = useSelector(getBalanceIsShown);
  const preferencesCenterMessage = useSelector(getAccountPrefsCenterMessages);
  const isBiometricEnabled = useSelector(getBiometricToggleState);
  const touchIdType = useSelector(getBiometricType);

  const [isLoadingBalance, setIsLoadingBalance] = useState(false);

  useEffect(() => {
    if (tvgConf().product === "tvg4") {
      mediatorClassic.subscribe(
        "HEADER_DESKTOP_SHOW_HIDE",
        (data: { section: string; value: PreferenceMetadataEnum }) => {
          if (data && data.section === "Account Balance") {
            dispatch(balanceShownAction(data.value));
          }
        }
      );
    }

    return () => {
      if (tvgConf().product === "tvg4") {
        mediatorClassic.unsubscribe("HEADER_DESKTOP_SHOW_HIDE");
      }
    };
  }, []);

  const onHideBalance = async () => {
    const balanceVisibility =
      balanceIsShown === ToggleStateEnum.OFF
        ? ToggleStateEnum.ON
        : ToggleStateEnum.OFF;

    await PreferenceService.setUserPref(
      accountNumber,
      "balance_is_shown",
      balanceVisibility
    );

    onToggleBalanceVisibility({
      module: "account",
      setting: "balance_visibility",
      value: balanceVisibility
    });

    if (tvgConf().product === "tvg4") {
      mediatorClassic.dispatch(
        "BALANCE_VISIBILITY_CHANGED",
        balanceVisibility,
        false,
        balanceShownAction(balanceVisibility)
      );
    }

    dispatch(balanceShownAction(balanceVisibility));
  };

  const onToggleBiometric = (toggle: boolean) => {
    // Post message to Android to save setting on device
    mediator.ios.dispatch({
      type: "PREFERENCE_TOUCH_ID",
      payload: {
        touchIdEnabled: toggle
      }
    });

    // Used to sync state change between Android and MEP
    dispatch(iOSTouchIdChangeAction({ isChanging: true }));
  };

  const biometricTypeUsedTitle = (biometricType: number) => {
    switch (biometricType) {
      case 1:
        return "Touch ID";
      case 2:
        return "Face ID";
      case 0:
      default:
        return get(preferencesCenterMessage, "account.auth.bioMetricTitle", "");
    }
  };

  const biometricSectionProps = {
    title: get(preferencesCenterMessage, "account.auth.title", ""),
    toggles: [
      {
        name: biometricTypeUsedTitle(touchIdType),
        onClick: () => onToggleBiometric(!isBiometricEnabled),
        defaultValue: isBiometricEnabled,
        qaLabel: "show-biometric"
      }
    ],
    qaLabel: "biometic-visibility-container"
  };

  const balanceSectionProps = {
    title: "Balance visibility",
    toggles: [
      {
        name: "Show balance",
        onClick: async () => {
          setIsLoadingBalance(true);
          await onHideBalance();
          if (tvgConf().product === "tvg4") {
            onGenericGtmEvent({
              gaEventAction:
                balanceIsShown === ToggleStateEnum.OFF
                  ? "Checkbox Checked"
                  : "Checkbox Unchecked",
              gaEventLabel: "Balance visibility - Show balance",
              module: "Account"
            });
          } else {
            onGenericGtmEvent({
              gaEventAction:
                balanceIsShown === ToggleStateEnum.OFF
                  ? "Toggled On"
                  : "Toggled Off",
              gaEventLabel: "Balance visibility - Show balance",
              module: "Account"
            });
          }
          setIsLoadingBalance(false);
        },
        defaultValue: balanceIsShown === "1",
        qaLabel: "show-balance",
        isLoading: isLoadingBalance
      }
    ],
    qaLabel: "balance-visibility-container"
  };

  return (
    <>
      {(tvgConf().product === "androidwrapper" ||
        tvgConf().product === "tvgandroid" ||
        tvgConf().product === "ios2") && (
        <ToggleGroup {...biometricSectionProps} />
      )}
      <ToggleGroup {...balanceSectionProps} />
    </>
  );
};

AccountComponent.defaultProps = {
  balanceIsShown: ToggleStateEnum.OFF,
  accountNumber: ""
};

export default AccountComponent;
