import React from "react";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { TabPanelProps } from "./types";
import { StyledView } from "./styled-components";

export const TabPanel = ({
  children,
  qaLabel = "tabPanel",
  isFullHeight,
  ...rest
}: TabPanelProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  return (
    <StyledView isFullHeight={isFullHeight} {...rest} {...viewTestProps}>
      {children}
    </StyledView>
  );
};

export { TabPanelProps };
