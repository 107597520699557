import React, { memo, forwardRef } from "react";
import type { TextInput } from "react-native";
import type { InputNumberProps } from "../types";
import { InputText } from "../inputText";

const INTEGER_REGEX = /^([0]|[1-9]\d*)?$/;
const DECIMAL_REGEX = /^(([0]|[1-9]\d*)([.,]\d{0,2})?)?$/;

export const InputNumber = forwardRef<TextInput, InputNumberProps>(
  (
    {
      hasDecimalPlaces = true,
      label = "",
      onChangeText,
      ...rest
    }: InputNumberProps,
    ref
  ) => {
    const changeTextHandler = (value: string) => {
      const isValid = value.match(
        hasDecimalPlaces ? DECIMAL_REGEX : INTEGER_REGEX
      );
      if (isValid && onChangeText) onChangeText(value.replace(/,/, "."));
    };

    return (
      <InputText
        ref={ref}
        label={label}
        {...rest}
        keyboardType={hasDecimalPlaces ? "decimal-pad" : "number-pad"}
        onChangeText={changeTextHandler}
      />
    );
  }
);

export default memo(InputNumber);
