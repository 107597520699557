import styled, { css } from "styled-components";
import { buildColor, Space } from "@tvg/design-system";

export const Container = styled.div<{ space: Space }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${buildColor("white", "900")};
  padding: ${({ space }) => space["space-4"]};

  @media only screen and (min-width: 768px) {
    padding: ${({ space }) => space["space-6"]};
  }
`;

export const TitleDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MTPContainer = styled.div<{ space: Space }>`
  min-width: 100%;
  display: flex;
  margin-bottom: ${({ space }) => space["space-5"]};
`;

export const MTPButton = styled.button<{ isEnable: boolean; space: Space }>`
  padding: ${({ space }) => space["space-3"]};
  background: ${buildColor("white", "900")};
  border: 1px solid ${buildColor("blue", "100")};
  flex: 1;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  ${({ isEnable }) =>
    isEnable &&
    css`
      background: ${buildColor("blue_accent", "100")};
      border: 2px solid ${buildColor("blue_accent", "500")};
    `}
`;

export const FooterContainer = styled.div<{ space: Space }>`
  display: flex;

  svg {
    vertical-align: middle;
    margin-left: ${({ space }) => space["space-1"]};
  }
`;
