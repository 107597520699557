import React from "react";
import {
  LabelWrapper,
  IconWrapper,
  StyledLabel,
  StyledOptionalTag
} from "./styled-components";
import { InputLabelProps } from "./types";
import { Icon } from "../icon";
import { useQaLabel } from "../../hooks";

export const InputLabel = ({
  label,
  isOptional = false,
  isChecked = false,
  qaLabel = "label",
  ...rest
}: InputLabelProps) => (
  <LabelWrapper>
    <StyledLabel {...rest} {...useQaLabel(qaLabel)}>
      {label}
    </StyledLabel>
    {isOptional && <StyledOptionalTag>(optional)</StyledOptionalTag>}
    {isChecked && (
      <IconWrapper>
        <Icon name="success" size="s" backgroundColor="white.000" />
      </IconWrapper>
    )}
  </LabelWrapper>
);
