import React from "react";
import { MessageWrapper } from "./styled-components";
import { InputMessageProps } from "./types";
import { Paragraph } from "../typography";
import { useQaLabel } from "../../hooks";
import { Icon } from "../icon";

export const InputMessage = ({
  message,
  isWarning = false,
  qaLabel = "message",
  ...rest
}: InputMessageProps) =>
  isWarning ? (
    <MessageWrapper {...rest} {...useQaLabel(qaLabel)}>
      <Icon name="warning" size="s" />
      <Paragraph qaLabel="warning-message" color="orange.600" ml="space-2">
        {message}
      </Paragraph>
    </MessageWrapper>
  ) : (
    <Paragraph qaLabel="message" color="grey.700" {...rest}>
      {message}
    </Paragraph>
  );
