import {
  UserBetPrefs,
  UserData,
  PreferencesPayload,
  PreferencesType
} from "./types";

export type FailBalanceRequestAction = {
  type: "USER_BALANCE_FAIL";
  payload: Error;
  error: true;
};

export type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE";
  payload: UserData;
};

export type UserPrefsSet = {
  type: "USER_PREFS_SET";
  payload: PreferencesPayload;
};

export type UserPrefsFail = {
  type: "USER_PREFS_FAIL";
  payload: {};
  error: true;
};

export type UpdateBalancePollerAction = {
  type: "USER_BALANCE_POLLER";
  payload: number;
};

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE";
  payload: {
    isLogged: boolean;
  };
};

export type SetUserAccountNumber = {
  type: "USER_ACCOUNT_NUMBER";
  payload: {
    accountNumber: string;
  };
};

export type UserPrefsBalanceShownAction = {
  type: "USER_PREFS_BALANCE_SHOWN";
  payload: boolean;
};

export type UserDefaultBetPrefsAction = {
  type: "SET_DEFAULT_BET_PREFERENCES";
  payload: UserBetPrefs;
};

export type UserPrefsBetConfirmationAction = {
  type: "USER_PREFS_BET_CONFIRMATION";
  payload: boolean;
};

export type UpdateUserBalance = {
  type: "USER_BALANCE_VALUE";
  payload: number;
};

export type SetNewPreferenceValue = {
  type: "SET_NEW_PREFERENCE_VALUE";
  payload: {
    preferenceKey: string;
    preferenceValue: string;
  };
};

export type UpdateBalanceActionType = {
  type: "USER_BALANCE_SUCCESS";
  payload: { balance: number };
};

export type UserActions =
  | UserSessionSuccessAction
  | UpdateBalanceActionType
  | UpdateBalancePollerAction
  | FailBalanceRequestAction
  | SetUserLoggedState
  | UserDefaultBetPrefsAction
  | SetUserAccountNumber
  | UserPrefsSet
  | UserPrefsFail
  | UserPrefsBalanceShownAction
  | UserPrefsBetConfirmationAction
  | SetNewPreferenceValue
  | UpdateUserBalance;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const successBalancePollerRequest = (
  balancePoller: number
): UpdateBalancePollerAction => ({
  type: "USER_BALANCE_POLLER",
  payload: balancePoller
});

export const failBalanceRequest = (err: Error): FailBalanceRequestAction => ({
  type: "USER_BALANCE_FAIL",
  payload: err,
  error: true
});

export const setUserPrefs = (
  userPrefs: PreferencesType,
  gotPreferences: boolean = false
): UserPrefsSet => ({
  type: "USER_PREFS_SET",
  payload: {
    preferences: userPrefs,
    gotPreferences
  }
});

export const failUserPrefs = (err: Error): UserPrefsFail => ({
  type: "USER_PREFS_FAIL",
  payload: err,
  error: true
});

export const setUserLoggedState = (isLogged: boolean): SetUserLoggedState => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});

export const updateUserBalance = (balance: number): UpdateUserBalance => ({
  type: "USER_BALANCE_VALUE",
  payload: balance
});
