import { get } from "lodash";
import { AppState } from "./reducers/index";

export const getStoreUserData = (store: AppState) => get(store, "userData");

export const getFeatureToggles = (store: AppState) =>
  get(store, "capi.featureToggles");

export const getStoreFeatures = (store: AppState) =>
  get(store, "capi.features");

export const getMessages = (store: AppState) => get(store, "capi.messages");
