import mediator from "@tvg/mediator";

interface DefaultBetAmountEvent {
  value: string;
}

const defaultBetAmount = ({ value }: DefaultBetAmountEvent) =>
  mediator.base.dispatch({
    type: "BET_PREFERENCES:DEFAULT_BET_AMOUNT",
    payload: {
      itemSelected:
        value === "useCustomBetAmount" ? "custom_amount" : "minimum_amount"
    }
  });

export default defaultBetAmount;
