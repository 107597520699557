import mediator from "@tvg/mediator";

interface PageViewGTMEvent {
  selectedTab: string;
}

const onPageViewGTMEvent = ({ selectedTab }: PageViewGTMEvent) =>
  mediator.base.dispatch({
    type: "BET_PREFERENCES:PAGE_VIEW",
    payload: {
      module: selectedTab.toLocaleLowerCase()
    }
  });

export default onPageViewGTMEvent;
