import { Dispatch } from "redux";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { History } from "@tvg/ts-types/History";
import {
  getUserData,
  setUserPrefs,
  updateUserBalance
} from "../../store/actions/user";

import { UserData, PreferencesType } from "../../store/actions/types";
import { RouterPayload } from "../types";

export const fetchUserData = (dispatch: Dispatch) => (data: UserData) =>
  dispatch(getUserData(data));

export const dispatchUserPrefs =
  (dispatch: Dispatch) => (userPrefs: PreferencesType) =>
    dispatch(setUserPrefs(userPrefs));

export const getUserBalance = (dispatch: Dispatch) => (balance: number) =>
  dispatch(updateUserBalance(balance));

export const updateTVG4Router = (data: RouterPayload) => {
  mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
};

export const updateRouter = (history: History) => () => {
  const pathWithParameters = window.location.href.replace(
    window.location.origin,
    ""
  );

  if (typeof history.replace === "function") {
    history.replace(pathWithParameters);
  }
};
