import type {
  ConfirmationModalType,
  PreferencesTabEnum,
  AlertTypePref,
  NotificationSwitch
} from "../utils/types";

export type SetSelectedTab = {
  type: "SET_SELECTED_TAB";
  payload: PreferencesTabEnum;
};

export type SetNotificationsSwitchAction = {
  type: "SET_NOTIFICATIONS_SWITCH";
  payload: {
    type: NotificationSwitch;
    value: boolean;
  };
};

export type PreferenceMetadataEnum = "0" | "1";

export type BalanceShownAction = {
  type: "USER_PREFS_BALANCE_SHOWN";
  payload: PreferenceMetadataEnum;
};

export type OpenConfirmationModalPrefAction = {
  type: "OPEN_CONFIRMATION_MODAL_PREF";
  payload: {
    type: ConfirmationModalType;
  };
};

export type CloseConfirmationModalPrefAction = {
  type: "CLOSE_CONFIRMATION_MODAL_PREF";
};

export type ShowAlertPrefAction = {
  type: "SHOW_ALERT_PREF";
  payload: {
    type: AlertTypePref;
    isToggling: boolean;
    shouldShowButton: boolean;
  };
};

export type SetMuteAllCommunications = {
  type: "SET_MUTE_ALL_COMMUNICATIONS";
  payload: boolean;
};

export type HideAlertPrefAction = {
  type: "HIDE_ALERT_PREF";
};

export type IOSTouchIdChangeAction = {
  type: "IOS_TOUCH_ID_CHANGE";
  payload: {
    isChanging: boolean;
  };
};

export type OpenPreferencesModalAction = {
  type: "OPEN_PREFERENCES_MODAL";
};

export type ClosePreferencesModalAction = {
  type: "CLOSE_PREFERENCES_MODAL";
};

export type Actions =
  | SetSelectedTab
  | SetNotificationsSwitchAction
  | BalanceShownAction
  | OpenConfirmationModalPrefAction
  | CloseConfirmationModalPrefAction
  | ShowAlertPrefAction
  | HideAlertPrefAction
  | IOSTouchIdChangeAction
  | SetMuteAllCommunications
  | OpenPreferencesModalAction
  | ClosePreferencesModalAction;

export const setSelectedTab = (
  selectedTab: PreferencesTabEnum
): SetSelectedTab => ({
  type: "SET_SELECTED_TAB",
  payload: selectedTab
});

export const setNotificationsSwitchAction = (
  type: NotificationSwitch,
  value: boolean
): SetNotificationsSwitchAction => ({
  type: "SET_NOTIFICATIONS_SWITCH",
  payload: {
    type,
    value
  }
});

export const balanceShownAction = (
  isBalanceShown: PreferenceMetadataEnum
): BalanceShownAction => ({
  type: "USER_PREFS_BALANCE_SHOWN",
  payload: isBalanceShown
});

export const openConfirmationModalPrefAction = (
  type: ConfirmationModalType
): OpenConfirmationModalPrefAction => ({
  type: "OPEN_CONFIRMATION_MODAL_PREF",
  payload: {
    type
  }
});

export const closeConfirmationModalPrefAction =
  (): CloseConfirmationModalPrefAction => ({
    type: "CLOSE_CONFIRMATION_MODAL_PREF"
  });

export const showAlertPrefAction = (
  type: AlertTypePref,
  isToggling = false,
  shouldShowButton = false
): ShowAlertPrefAction => ({
  type: "SHOW_ALERT_PREF",
  payload: {
    type,
    isToggling,
    shouldShowButton
  }
});

export const hideAlertPrefAction = (): HideAlertPrefAction => ({
  type: "HIDE_ALERT_PREF"
});

export const setMuteAllCommunications = (
  muteAll: boolean
): SetMuteAllCommunications => ({
  type: "SET_MUTE_ALL_COMMUNICATIONS",
  payload: muteAll
});

export const iOSTouchIdChangeAction = (payload: {
  isChanging: boolean;
}): IOSTouchIdChangeAction => ({
  type: "IOS_TOUCH_ID_CHANGE",
  payload
});

export const openPreferencesModalAction = (): OpenPreferencesModalAction => ({
  type: "OPEN_PREFERENCES_MODAL"
});

export const closePreferencesModalAction = (): ClosePreferencesModalAction => ({
  type: "CLOSE_PREFERENCES_MODAL"
});
