import React, { memo, forwardRef } from "react";
import type { TextInput } from "react-native";
import { useQaLabel } from "../../../hooks";
import type { InputTextProps } from "../types";
import { InputWrapper } from "../styled-components";
import { InputField } from "../inputField";
import { InputLabel } from "../inputLabel";
import { InputMessage } from "../inputMessage";

export const InputText = forwardRef<TextInput, InputTextProps>(
  (
    {
      label = "",
      messageAbove = "",
      messageBellow = "",
      isOptional = false,
      isChecked = false,
      isInvalid = false,
      warningMessage = "",
      qaLabel,
      onBlur,
      onChange,
      onFocus,
      ...rest
    }: InputTextProps,
    ref
  ) => (
    <InputWrapper {...rest} {...useQaLabel(qaLabel)}>
      <InputLabel label={label} isOptional={isOptional} isChecked={isChecked} />
      {messageAbove !== "" && (
        <InputMessage message={messageAbove} marginTop="space-3" />
      )}
      <InputField
        ref={ref}
        isInvalid={isInvalid}
        qaLabel={`${qaLabel}-input-field`}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        {...rest}
        my="space-2"
        mx={0}
        p={0}
      />
      {isInvalid && warningMessage && (
        <InputMessage
          message={warningMessage}
          isWarning={isInvalid}
          mx={0}
          mt={2}
          mb="space-3"
        />
      )}
      {messageBellow !== "" && <InputMessage message={messageBellow} />}
    </InputWrapper>
  )
);

export default memo(InputText);
