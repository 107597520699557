import { UserActions } from "../../actions/user";
import { UserState } from "../types";

export const initialState: UserState = {
  error: false,
  hasRequested: false,
  logged: false,
  logging: false,
  loginPin: false,
  tvg3token: "",
  userLogin: false,
  userLogout: true,
  allPromos: [],
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "PORT-Generic",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: ["USER"]
  },
  wasLogin: false,
  preferences: {},
  gotPreferences: false,
  gotPromos: false,
  optedInPromos: {},
  balance: null,
  balancePoller: 120,
  returningUser: false,
  brazeContentCards: {
    unviewed: 0,
    totalCards: 0
  }
};

const userDataReducer = (
  state: UserState = initialState,
  action: UserActions
) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_PREFS_SET": {
      return {
        ...state,
        preferences: action.payload.preferences,
        gotPreferences: action.payload.gotPreferences
      };
    }
    case "USER_LOGGED_STATE": {
      return { ...state, logged: action.payload.isLogged };
    }
    case "USER_ACCOUNT_NUMBER": {
      return {
        ...state,
        user: { ...state.user, accountNumber: action.payload.accountNumber }
      };
    }
    case "USER_PREFS_BALANCE_SHOWN": {
      return {
        ...state,
        preferences: { ...state.preferences, balance_is_shown: action.payload }
      };
    }
    case "SET_DEFAULT_BET_PREFERENCES": {
      return {
        ...state,
        preferences: { ...state.preferences, bet_prefs: action.payload }
      };
    }
    case "USER_PREFS_BET_CONFIRMATION": {
      return {
        ...state,
        preferences: { ...state.preferences, show_bet_confirm: action.payload }
      };
    }
    case "USER_BALANCE_POLLER": {
      return { ...state, balancePoller: action.payload };
    }
    case "USER_BALANCE_VALUE": {
      return { ...state, balance: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
