import { combineReducers } from "redux";

import preferencesReducer, {
  initialState as initialPreferencesState
} from "@tvg/sh-lib-preferences/redux/reducers";

import { CapiState, UserState, PreferencesState } from "./types";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

export type AppState = {
  capi: CapiState;
  userData: UserState;
  preferences: PreferencesState;
};

export type RootState = ReturnType<typeof rootReducer>;

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  preferences: initialPreferencesState
} as AppState;

const rootReducer = combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  preferences: preferencesReducer
});

export default rootReducer;
