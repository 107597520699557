import mediator from "@tvg/mediator";

interface CustomBetAmountEvent {
  fieldInput: string;
  betType: string;
}

const customBetAmount = ({ fieldInput, betType }: CustomBetAmountEvent) =>
  mediator.base.dispatch({
    type: "BET_PREFERENCES:CUSTOM_BET_AMOUNT",
    payload: {
      fieldInput,
      betType
    }
  });

export default customBetAmount;
