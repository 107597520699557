import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";
import { Link } from "react-router-dom";

export const Container = styled.div<{ isMobileDevice?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
      }
    `};
`;

export const ContentContainer = styled.div<{ isMobileDevice?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space["space-4"]};
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        padding: ${({ theme }) =>
          `${theme.space["space-6"]} ${theme.space["space-6"]} ${theme.space["space-4"]} ${theme.space["space-6"]}`};
      }
    `};
`;

export const TogglesContainer = styled.div<{ isMobileDevice?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        padding: ${({ theme }) =>
          `${theme.space["space-4"]} ${theme.space["space-6"]} ${theme.space["space-6"]}`};
      }
    `};
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;

  [data-qa-label="switch-spinner"] {
    margin-right: ${({ theme }) => theme.space["space-2"]};
  }
`;

export const ToggleContainer = styled.div<{
  isTablet?: boolean;
  isDesktop?: boolean;
  isMobileDevice?: boolean;
}>`
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.space["space-4"]};
  align-items: center;
  justify-content: space-between;
  ${({ isMobileDevice, theme, isTablet, isDesktop }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        ${!isTablet &&
        css`
          box-shadow: none;
          &:not(:first-child) {
            margin-top: ${theme.space["space-4"]};
          }
        `}
        padding: ${isDesktop ? 0 : theme.space["space-6"]};
        justify-content: ${isTablet ? "space-between" : "flex-start"};
      }
    `};
`;

export const ButtonContainer = styled(Link)<{
  isTablet: boolean;
  isMobileDevice?: boolean;
}>`
  display: flex;
  padding: ${({ theme }) =>
    `${theme.space["space-5"]} ${theme.space["space-4"]}`};
  justify-content: space-between;
  background: ${({ theme }) => theme.colorTokens.background.surface};
  text-decoration: none;
  ${({ isMobileDevice, theme, isTablet }) =>
    !isMobileDevice &&
    css`
      @media only screen and ${breakpoints.tablet.min.sm} {
        ${!isTablet &&
        css`
          box-shadow: none;
        `}
        padding: ${`${theme.space["space-7"]} ${theme.space["space-6"]}`};
      }
    `};
`;
