import mediator from "@tvg/mediator";

interface DefaultBetTypeSelectedEvent {
  value: string;
}

const onChangeDefaultBetTypeSelected = ({
  value
}: DefaultBetTypeSelectedEvent) =>
  mediator.base.dispatch({
    type: "BET_PREFERENCES:DEFAULT_BET_TYPE_SELECTED",
    payload: {
      defaultBetTypeSelected: value
    }
  });

export default onChangeDefaultBetTypeSelected;
