import { useDispatch } from "react-redux";
import type { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import { setSelectedTab } from "@tvg/sh-lib-preferences/redux/actions";
import { onGenericGtmEvent } from "../utils/gtm/generic";

const useTabSelection = () => {
  const dispatch = useDispatch();

  return (tabSelected: PreferencesTabEnum, selectedTab: PreferencesTabEnum) => {
    if (selectedTab === tabSelected) {
      return;
    }

    dispatch(setSelectedTab(tabSelected));

    onGenericGtmEvent({
      gaEventAction: "Tab Opened",
      gaEventLabel: tabSelected,
      module: selectedTab
    });
  };
};

export default useTabSelection;
