import React, { useState, useMemo, useEffect } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import tvgConf from "@tvg/conf";
import queryString from "query-string";
import { getIsLogged, getResidenceState } from "@urp/store-selectors";
import { getEmailUnsubscribeMessage } from "@tvg/sh-lib-preferences/redux/selectors";
import { replaceAll } from "@tvg/utils/generalUtils";
import useEmailUnsubscribe from "@tvg/sh-lib-preferences/hooks/useEmailUnsubscribe";
import {
  handleOpenHome,
  handleOpenPreferences,
  handleUnsubscribeClick
} from "@tvg/sh-lib-preferences/utils/emailUnsubscribe";

import { AlertInline, Button, Header, Paragraph } from "@tvg/design-system";
import EmailUnsubscribeBodyMask from "@tvg/atomic-ui/_static/Masks/emailUnsubscribe";
import { useNavigate } from "@tvg/custom-hooks";

import {
  EmailParams,
  MessageBlock,
  SubscriptionGroupEnum
} from "@tvg/sh-lib-preferences/utils/types";

import {
  BodyTextContainer,
  DefaultContainer,
  MaskContainer,
  Text
} from "./styled-components";

import onPageViewGTMEvent from "../../utils/gtm/pageView";

const EmailUnsubscribe = () => {
  const isLogged = useSelector(getIsLogged);
  const capiMessage = useSelector(getEmailUnsubscribeMessage);
  const { subscriptionGroups } = capiMessage;
  const isMobile = tvgConf().device === "";

  const residenceState = useSelector(getResidenceState);

  const [isLoading, setIsLoading] = useState(true);
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [showFallbackScreen, setShowFallbackScreen] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const [emailParams, setEmailParams] = useState<EmailParams>({
    accountId: "",
    email: "",
    category: SubscriptionGroupEnum.email_offers_news
  });
  const location = useLocation();
  const history = useNavigate();

  useEmailUnsubscribe(location.search, setEmailParams, setShowFallbackScreen);

  useEffect(() => {
    const accountId =
      (get(queryString.parse(location.search), "accountId", "") as string) ||
      "0";
    if (!isLoading) {
      onPageViewGTMEvent({
        accountId,
        selectedTab: "",
        residenceState,
        pathname: location.pathname,
        userIsLoggedIn: isLogged
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (capiMessage?.unsubscribe?.name !== "" && isLoading) {
      setIsLoading(false);
    }
  }, [JSON.stringify(capiMessage)]);

  const renderText = (text: string, isBold: boolean) => (
    <Text
      dangerouslySetInnerHTML={{
        __html: replaceAll(
          text,
          "@@@subscriptionGroupName@@@",
          isBold
            ? `<b>${subscriptionGroups[emailParams.category]}</b>`
            : subscriptionGroups[emailParams.category]
        )
      }}
    />
  );

  const renderAlert = (message: MessageBlock) => (
    <>
      {showSuccessScreen ? (
        <AlertInline
          type={isMobile ? "fixed" : "floating"}
          variant="success"
          message={replaceAll(
            message.label || "",
            "@@@subscriptionGroupName@@@",
            subscriptionGroups[emailParams.category]
          )}
          qaLabel={`${message.name}-alert-message`}
          mb={isMobile ? 0 : 20}
        />
      ) : (
        <AlertInline
          type={isMobile ? "fixed" : "floating"}
          variant="error"
          message={replaceAll(
            message.label || "",
            "@@@subscriptionGroupName@@@",
            subscriptionGroups[emailParams.category]
          )}
          qaLabel={`${message.name}-alert-message`}
          mb={isMobile ? 0 : 20}
        />
      )}
    </>
  );

  const renderBodyContent = (message: MessageBlock) => (
    <>
      {(showSuccessScreen || (showErrorScreen && !showFallbackScreen)) &&
        isMobile &&
        renderAlert(message)}
      <DefaultContainer isMobile={isMobile}>
        {(showSuccessScreen || (showErrorScreen && !showFallbackScreen)) &&
          !isMobile &&
          renderAlert(message)}
        <Header tag="h3" qaLabel={`${message.name}-header`} mb={8}>
          {message.title}
        </Header>
        <BodyTextContainer>
          {message.bodyContent.map((text, index) => (
            <Paragraph
              qaLabel={`${message.name}-body-text-${index + 1}`}
              mb={8}
              key={`${message.name}-${index + 1}`}
            >
              {renderText(text.description, true)}
            </Paragraph>
          ))}
        </BodyTextContainer>
        <Button
          variant="primary"
          qaLabel={`${message.name}-primary-button`}
          isStretched
          isLoading={showButtonLoading}
          onPress={
            showSuccessScreen
              ? handleOpenPreferences(
                  isLogged,
                  history,
                  emailParams.category,
                  get(message, "buttonTextPrimary", "")
                )
              : () =>
                  handleUnsubscribeClick(
                    setShowSuccessScreen,
                    setShowErrorScreen,
                    setShowButtonLoading,
                    emailParams,
                    subscriptionGroups
                  )
          }
          mb={8}
        >
          {renderText(message.buttonTextPrimary!, false)}
        </Button>
        <Button
          variant="tertiary"
          qaLabel={`${message.name}-secondary-button`}
          isStretched
          onPress={
            showSuccessScreen || showFallbackScreen
              ? handleOpenHome(
                  history,
                  emailParams.category,
                  get(message, "buttonTextSecondary", "")
                )
              : handleOpenPreferences(
                  isLogged,
                  history,
                  emailParams.category,
                  get(message, "buttonTextSecondary", "")
                )
          }
        >
          {message.buttonTextSecondary!}
        </Button>
      </DefaultContainer>
    </>
  );

  const renderLoadingMask = () => (
    <MaskContainer>
      <EmailUnsubscribeBodyMask />
    </MaskContainer>
  );

  const bodyContentRender = () => {
    if (showErrorScreen) {
      return renderBodyContent(capiMessage.error);
    }

    if (showFallbackScreen) {
      return renderBodyContent(capiMessage.fallback);
    }

    return renderBodyContent(
      showSuccessScreen ? capiMessage.confirmation : capiMessage.unsubscribe
    );
  };

  return useMemo(
    () => (
      <>
        {isLoading && renderLoadingMask()}
        {!isLoading && bodyContentRender()}
      </>
    ),
    [
      isLoading,
      showErrorScreen,
      showSuccessScreen,
      showButtonLoading,
      emailParams
    ]
  );
};

export default EmailUnsubscribe;
