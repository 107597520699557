import React from "react";
import { Paragraph, buildColor, Icon, useTheme } from "@tvg/design-system";
import {
  Container,
  FooterContainer,
  MTPButton,
  MTPContainer,
  TitleDescriptionContainer
} from "./styled-components";

interface RaceAlertsProps {
  title: string;
  description: string;
  mtpRaceAlerts: Array<{
    name: string;
    onClick: () => void;
    enable: boolean;
  }>;
  qaLabel: string;
  footer: Array<string>;
}

const PreferencesRaceAlerts = ({
  title,
  description,
  mtpRaceAlerts,
  qaLabel,
  footer
}: RaceAlertsProps) => {
  const { space } = useTheme();
  const enabledColor = buildColor("blue_accent", "700");
  const defaultColor = buildColor("grey", "900");

  return (
    <Container data-qa-label={`${qaLabel}-container`} space={space}>
      <TitleDescriptionContainer>
        <Paragraph
          qaLabel={`${qaLabel}-title`}
          fontFamily="medium"
          fontSize={16}
          color={defaultColor}
          mb={8}
        >
          {title}
        </Paragraph>
        <Paragraph
          qaLabel={`${qaLabel}-description`}
          fontFamily="regular"
          fontSize={14}
          color={buildColor("grey", "700")}
          mb={16}
        >
          {description}
        </Paragraph>
      </TitleDescriptionContainer>
      <MTPContainer space={space}>
        <MTPButton
          space={space}
          isEnable={mtpRaceAlerts[0].enable}
          onClick={mtpRaceAlerts[0].onClick}
          data-qa-label={`${qaLabel}-mtp-0-button`}
        >
          <Paragraph
            qaLabel={`${qaLabel}-mtp-0-text`}
            fontFamily="medium"
            fontSize={14}
            color={mtpRaceAlerts[0].enable ? enabledColor : defaultColor}
          >
            {mtpRaceAlerts[0].name}
          </Paragraph>
        </MTPButton>
        <MTPButton
          space={space}
          isEnable={mtpRaceAlerts[1].enable}
          onClick={mtpRaceAlerts[1].onClick}
          data-qa-label={`${qaLabel}-mtp-2-button`}
        >
          <Paragraph
            qaLabel={`${qaLabel}-mtp-2-text`}
            fontFamily="medium"
            fontSize={14}
            color={mtpRaceAlerts[1].enable ? enabledColor : defaultColor}
          >
            {mtpRaceAlerts[1].name}
          </Paragraph>
        </MTPButton>
        <MTPButton
          space={space}
          isEnable={mtpRaceAlerts[2].enable}
          onClick={mtpRaceAlerts[2].onClick}
          data-qa-label={`${qaLabel}-mtp-5-button`}
        >
          <Paragraph
            qaLabel={`${qaLabel}-mtp-5-text`}
            fontFamily="medium"
            fontSize={14}
            color={mtpRaceAlerts[2].enable ? enabledColor : defaultColor}
          >
            {mtpRaceAlerts[2].name}
          </Paragraph>
        </MTPButton>
      </MTPContainer>
      <FooterContainer space={space}>
        <Paragraph
          qaLabel={`${qaLabel}-footer-text`}
          fontFamily="regular"
          fontSize={14}
          color={buildColor("grey", "700")}
        >
          <>
            {footer[0]}
            <Icon name="alert" size="s" lineColor="grey.800" />
            {footer[1]}
          </>
        </Paragraph>
      </FooterContainer>
    </Container>
  );
};

export default PreferencesRaceAlerts;
