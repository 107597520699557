import { isTvg5 } from "@tvg/utils/generalUtils";
/* eslint-disable arrow-body-style */
import { History } from "@tvg/ts-types/History";
import mediator from "@tvg/mediator";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import tvgConf from "@tvg/conf";
import uc from "@tvg/api/uc";
import { onGenericGtmEvent } from "@tvg/pref/src/utils/gtm/generic";

import {
  EmailParams,
  LoginError,
  LoginSuccess,
  UnaryFn,
  SubscriptionGroupEnum
} from "./types";

export const loginSuccessCallback =
  (history: History) =>
  // @ts-ignore
  (error: LoginError, success: LoginSuccess) => {
    if (success?.status === "success") {
      // TODO: Integrate GTM
      history.push("/preferences");
    }
  };

export const handleOpenLogin = (history: History) =>
  mediator.base.dispatch({
    type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
    payload: {
      callback: loginSuccessCallback(history)
    }
  });

export const handleOpenPreferences =
  (
    isLogged: boolean,
    history: History,
    category: SubscriptionGroupEnum,
    message?: string
  ) =>
  () => {
    onGenericGtmEvent({
      gaEventAction: "CTA Clicked",
      gaEventLabel: message || "",
      module: "Email Unsubscribe " + category
    });

    if (isLogged) {
      if (tvgConf().product === "tvg4" && !isTvg5()) {
        mediatorClassic.dispatch("TVG4_NAVIGATION", {
          route: "/preferences"
        });
      } else {
        history.push("/preferences");
      }
    } else {
      handleOpenLogin(history);
    }
  };

export const handleOpenHome =
  (history: History, category?: SubscriptionGroupEnum, message?: string) =>
  () => {
    const prop = category || SubscriptionGroupEnum.email_offers_news;

    onGenericGtmEvent({
      gaEventAction: "CTA Clicked",
      gaEventLabel: message || "",
      module: "Email Unsubscribe " + prop
    });
    if (tvgConf().product === "tvg4" && !isTvg5()) {
      mediatorClassic.dispatch("TVG4_NAVIGATION", {
        route: "/"
      });
    } else {
      history.push("/home");
    }
  };

export const handleUnsubscribeClick = (
  setShowSuccessScreen: UnaryFn<boolean, void>,
  setShowErrorScreen: UnaryFn<boolean, void>,
  setShowButtonLoading: UnaryFn<boolean, void>,
  { accountId, email, category }: EmailParams,
  subscriptionGroups?: { [key: string]: string }
) => {
  const buttonLabel = subscriptionGroups
    ? `Unsubscribe ${subscriptionGroups[category]}`
    : "";

  setShowButtonLoading(true);
  if (tvgConf().brand === "tvg") {
    onGenericGtmEvent({
      gaEventAction: "CTA Clicked",
      gaEventLabel: buttonLabel,
      module: "Email Unsubscribe " + category
    });
  }
  return uc
    .setUserSubscription(accountId, category, email, false)

    .then(() => {
      setShowSuccessScreen(true);
      if (tvgConf().brand === "tvg") {
        onGenericGtmEvent({
          gaEventAction: "Unsubscribe Success",
          gaEventLabel: buttonLabel,
          module: "Email Unsubscribe " + category
        });
      }
    })
    .catch(() => {
      setShowSuccessScreen(false);
      setShowErrorScreen(true);
      if (tvgConf().brand === "tvg") {
        onGenericGtmEvent({
          gaEventAction: "Unsubscribe Fail",
          gaEventLabel: "Missing required url parameter",
          module: "Email Unsubscribe"
        });
      }
    })
    .finally(() => setShowButtonLoading(false));
};

export const handleUnsubscribeClickAllMainStatus = (
  setShowSuccessScreen: UnaryFn<boolean, void>,
  setShowErrorScreen: UnaryFn<boolean, void>,
  setShowButtonLoading: UnaryFn<boolean, void>,
  { accountId, email }: EmailParams
) => {
  setShowButtonLoading(true);
  return uc
    .unsubscribeAllMainStatus(accountId, email)
    .then(() => setShowSuccessScreen(true))
    .catch(() => setShowErrorScreen(true))
    .finally(() => setShowButtonLoading(false));
};
